import { LinkItem } from "@gn2studio/gn2.library.common";

const SiteData = {
    tags : [

    ] as LinkItem[],
    admintag : [

    ] as LinkItem[]
};

export default SiteData;