import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container mx-auto h-screen flex items-center justify-center p-2" }
const _hoisted_2 = { class: "loginbox w-full max-w-4xl overflow-hidden flex justify-between" }
const _hoisted_3 = { class: "left-box box-content p-8 bg-orange-50 dark:bg-gray-950 rounded-lg shadow-sm w-2/5" }
const _hoisted_4 = { class: "w-full block dark:hidden" }
const _hoisted_5 = { class: "w-full hidden dark:block" }
const _hoisted_6 = { class: "text-orange-200 mt-3" }
const _hoisted_7 = { class: "text-2xl font-bold font-sans" }
const _hoisted_8 = { class: "mt-1 text-gray-600 dark:text-gray-400" }
const _hoisted_9 = { class: "grid grid-cols-1 xl:grid-cols-2 gap-4 mt-6" }
const _hoisted_10 = { class: "grid grid-rows-1 px-2" }
const _hoisted_11 = { class: "grid grid-rows-1 px-2" }
const _hoisted_12 = { class: "grid grid-cols-1 xl:grid-cols-2 mt-4" }
const _hoisted_13 = { class: "flex justify-start items-center" }
const _hoisted_14 = { class: "cursor-pointer flex" }
const _hoisted_15 = { class: "text-gray-600 dark:text-gray-200 ml-1 text-xs mt-0.5" }
const _hoisted_16 = { class: "text-gray-600 dark:text-gray-300 text-sm text-right" }
const _hoisted_17 = { href: "/forgot-password" }
const _hoisted_18 = { class: "w-full mt-4" }

import { GInput, GButton } from '@/components/atoms';
import { AuthService } from '@/services';
import { MessageBox } from '@/utility';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';
import { HeadSet } from '@/utility';
import { MemberRepository } from '@gn2studio/gn2.library.common';
import { useMemberStore,useGlobalStore } from '@/store';
import config from '@/config';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  setup(__props) {

const { t, locale } = useI18n();
const auth = new AuthService();
const route = useRoute();
const memberStore = useMemberStore();
const site = useGlobalStore();
const head = new HeadSet();
head.title = 'Login - MuseStudio';

useHead(head.CreateJson());

var pageinfo = ref({
  key:0,
  isLoaded:false,
  data: {
    email:'',
    password:'',
    isSave:false
  },
  callbackUrl: '/'
});

onMounted(async () => {
  if (route.query.backurl !== null && route.query.backurl !== undefined) {
    pageinfo.value.callbackUrl = String(route.query.backurl).trim() ?? '';
  }
  
  const accessToken = await auth.getAccessToken();
  if (accessToken !== null && accessToken !== undefined && String(accessToken).trim() !== '') {
    const memberRep = new MemberRepository(config.apis.member, accessToken);
    const rst = await memberRep.GetUser();
    if (rst.check) {
      await auth.refreshSilent();
      window.location.href = pageinfo.value.callbackUrl;
    }
  }


  setTimeout(() => {
      site.setValue('loading', false);
  }, 100);
});

const fnNextStep = () => {
  document.getElementById('input_password')?.focus();
};

const fnLogin = async () => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /\S{6,}/;

  if (pageinfo.value.data.email === "") {
    MessageBox.Alert(t('common.requiredEmail'));
  } else if (emailRegex.test(pageinfo.value.data.email) === false) {
    MessageBox.Alert(t('common.regexEmail'));
  } else if (pageinfo.value.data.password === "") {
    MessageBox.Alert(t('common.requiredPassword'));
  } else if (passwordRegex.test(pageinfo.value.data.password) === false) {
    MessageBox.Alert(t('common.regexPassword'));
  } else {
    site.setValue('loading', true);
    let rst = await auth.apiLogin(pageinfo.value.data.email, pageinfo.value.data.password, pageinfo.value.data.isSave);
    if (rst.check) {
      const accessToken = await auth.getAccessToken();
      const memberRep = new MemberRepository(config.apis.member, accessToken);
      const rst = await memberRep.GetUser();
      if (rst.check) {
        memberStore.setMember(rst.data);
      }
      window.location.href = pageinfo.value.callbackUrl;
    } else {
      MessageBox.Alert(rst.message);
    }
    site.setValue('loading', false);
  }
};


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, {
            to: "/",
            class: "flex items-end space-x-2"
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("img", {
                src: "/logo/muselogo.png",
                width: "102",
                height: "32",
                alt: "MuseStudio"
              }, null, -1),
              _createElementVNode("span", { class: "playwrite-de-grund-guides-regular text-amber-700 text-xs mt-1" }, "muse with gn2studio", -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_router_link, {
            to: "/",
            class: "flex items-end space-x-2"
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createElementVNode("img", {
                src: "/logo/muselogo.png",
                width: "102",
                height: "32",
                alt: "MuseStudio"
              }, null, -1),
              _createElementVNode("span", { class: "playwrite-de-grund-guides-regular text-amber-700 text-xs mt-1" }, "muse with gn2studio", -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("h3", _hoisted_7, _toDisplayString(_unref(t)('site.welcome')), 1),
          _createElementVNode("p", _hoisted_8, [
            _createTextVNode(_toDisplayString(_unref(t)('site.welcome2')) + ", " + _toDisplayString(_unref(t)('common.noaccount')) + " ", 1),
            _createVNode(_component_router_link, {
              to: "/regist",
              class: "text-orange-500 font-bold"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('system.Signup')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _cache[5] || (_cache[5] = _createElementVNode("label", {
              for: "email",
              class: "text-gray-700 dark:text-gray-300 block mb-1"
            }, "Email", -1)),
            _createVNode(_unref(GInput), {
              type: "email",
              placeholder: _unref(t)('common.requiredEmail'),
              modelValue: _unref(pageinfo).data.email,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(pageinfo).data.email) = $event)),
              class: "w-full p-2 border rounded",
              onKeyup: _withKeys(fnNextStep, ["enter"])
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[6] || (_cache[6] = _createElementVNode("label", {
              for: "password",
              class: "text-gray-700 dark:text-gray-300 block mb-1"
            }, "Password", -1)),
            _createVNode(_unref(GInput), {
              id: "input_password",
              type: "password",
              placeholder: _unref(t)('system.requiredPassword'),
              modelValue: _unref(pageinfo).data.password,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(pageinfo).data.password) = $event)),
              class: "w-full p-2 border rounded",
              onKeyup: _withKeys(fnLogin, ["enter"])
            }, null, 8, ["placeholder", "modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("label", _hoisted_14, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(pageinfo).data.isSave) = $event)),
                class: "block border-none"
              }, null, 512), [
                [_vModelCheckbox, _unref(pageinfo).data.isSave]
              ]),
              _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(t)('system.stayLogin')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("a", _hoisted_17, _toDisplayString(_unref(t)('system.forgot')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createVNode(_unref(GButton), {
            onClick: fnLogin,
            size: "md",
            class: "w-full py-2 bg-orange-500 text-white font-bold rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('system.Login')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "right-box w-3/5 ml-2 hidden md:block" }, [
        _createElementVNode("img", {
          src: "/banners/editor.jpg",
          alt: "Banner",
          class: "w-full h-auto rounded-md shadow-sm"
        })
      ], -1))
    ])
  ]))
}
}

})