import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-slate-950 mt-12" }
const _hoisted_2 = { class: "container flex flex-col-reverse justify-between px-6 py-10 mx-auto space-y-8 lg:flex-row lg:space-y-0" }
const _hoisted_3 = { class: "flex flex-col-reverse items-center justify-between space-y-12 lg:flex-col lg:space-y-0 lg:items-start" }
const _hoisted_4 = { class: "flex justify-around space-x-32" }
const _hoisted_5 = { class: "flex flex-col space-y-3 text-white" }
const _hoisted_6 = { class: "flex flex-col space-y-3 text-white" }
const _hoisted_7 = { class: "flex flex-col justify-between" }
const _hoisted_8 = { class: "flex justify-center space-x-4 text-white" }
const _hoisted_9 = ["href"]
const _hoisted_10 = ["href"]
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "text-gray-300 mt-2" }
const _hoisted_13 = { class: "flex justify-start lg:justify-end space-x-5 text-white mt-1" }

import { onMounted, ref } from 'vue';
import { GContainer,GBetweenSide } from '../atoms';
import { ValidateHelper } from '@gn2studio/gn2.library.common';
import router from '@/router';
import config from '@/config';
import { useI18n } from 'vue-i18n';

interface containerParameter {
    class?:string|null
}
export default /*@__PURE__*/_defineComponent({
  __name: 'Footer',
  props: {
    class: {}
  },
  setup(__props: any) {

;

const { t, locale } = useI18n();
const property = __props;
const validate = new ValidateHelper();

var componentInfo = ref({
    year:0,
    class:'',
    documentUrl:'',
    linkUrl:''
});

onMounted(() => {
    let dt = new Date();
    componentInfo.value.year = dt.getFullYear();
    componentInfo.value.class = property.class ?? '';
});

const fnInnerUrl = () => {
    if (false === validate.IsNullOrWhiteSpace(componentInfo.value.documentUrl)) {
        router.push(componentInfo.value.documentUrl);
    }
};

const fnOuterUrl = () => {
    if (false === validate.IsNullOrWhiteSpace(componentInfo.value.linkUrl)) {
        window.open(componentInfo.value.linkUrl);
    }
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("footer", _hoisted_1, [
      _createVNode(_unref(GContainer), null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_router_link, { to: "/" }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createElementVNode("img", {
                    src: "/logo/Logo_Blue.png",
                    alt: "logo image",
                    class: "w-[146px] h-auto filter invert brightness-0"
                  }, null, -1)
                ])),
                _: 1
              }),
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "" }, null, -1))
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_router_link, {
                  to: "/",
                  class: "hover:text-gray-100"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Home")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  to: "/Intro",
                  class: "hover:text-gray-100"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("회사소개")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  to: "/History",
                  class: "hover:text-gray-100"
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("서비스")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  to: "/Service",
                  class: "hover:text-gray-100"
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("업력")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_6, [
                _cache[7] || (_cache[7] = _createElementVNode("a", {
                  href: "https://muse.gn2studio.com",
                  target: "_blank",
                  class: "hover:text-gray-100"
                }, "뮤즈스튜디오", -1)),
                _cache[8] || (_cache[8] = _createElementVNode("a", {
                  href: "https://www.merrytoktok.com",
                  target: "_blank",
                  class: "hover:text-gray-100"
                }, "메리톡톡", -1)),
                _createVNode(_component_router_link, {
                  to: "/customer",
                  class: "hover:text-gray-100"
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("고객센터")
                  ])),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _cache[12] || (_cache[12] = _createElementVNode("div", null, null, -1)),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("a", {
                  href: _unref(config).link.facebook,
                  target: "_blank"
                }, _cache[9] || (_cache[9] = [
                  _createElementVNode("i", { class: "fa-brands fa-facebook" }, null, -1)
                ]), 8, _hoisted_9),
                _createElementVNode("a", {
                  href: _unref(config).link.youtube,
                  target: "_blank"
                }, _cache[10] || (_cache[10] = [
                  _createElementVNode("i", { class: "fa-brands fa-youtube" }, null, -1)
                ]), 8, _hoisted_10),
                _createElementVNode("a", {
                  href: _unref(config).link.blog,
                  target: "_blank"
                }, _cache[11] || (_cache[11] = [
                  _createElementVNode("i", { class: "fa-solid fa-blog" }, null, -1)
                ]), 8, _hoisted_11)
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("footer", {
      class: _normalizeClass(["bg-slate-700", _unref(componentInfo).class])
    }, [
      _createVNode(_unref(GContainer), null, {
        default: _withCtx(() => [
          _createVNode(_unref(GBetweenSide), { class: "p-2" }, {
            left: _withCtx(() => [
              _createElementVNode("div", _hoisted_12, [
                _createTextVNode("©" + _toDisplayString(_unref(componentInfo).year) + " ", 1),
                _cache[13] || (_cache[13] = _createElementVNode("span", null, "gn2studio.com", -1)),
                _cache[14] || (_cache[14] = _createTextVNode(". All rights reserved."))
              ])
            ]),
            right: _withCtx(() => [
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_router_link, { to: "/document/privacy" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('system.Privacy')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_router_link, { to: "/document/term" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('system.Terms')), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ], 2)
  ], 64))
}
}

})