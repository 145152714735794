<template>
  <GContainer :key="`content_layer_${componentInfo.key}`" v-if="componentInfo.isLoaded" :class="componentInfo.class" class="mt-3">
    <div class="w-full flex justify-start p-6 lg:p-1 lg:space-x-8 pt-3">
        <div class="hidden lg:block w-1/6">
            <CustomerMenu :position="1"></CustomerMenu>
        </div>
        <div class="w-full lg:w-5/6">
            <div class="w-full mt-4">
                <TitleBox :title="t(`common.notice`)" icon="fa-solid fa-bell"></TitleBox>
            </div>
            <CustomerListPage :class="componentInfo.class" :selectedContent="goContent" :key="`content_list_${componentInfo.key}`" />
        </div>
    </div>
  </GContainer>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { useRoute,useRouter } from 'vue-router';
    import { GContainer,CustomerMenu,CustomerListPage,WideBanner,TitleBox } from '@/components';
    import { BoardContent } from '@gn2studio/gn2.library.common';
    import { useGlobalStore } from '@/store';
    import { useI18n } from 'vue-i18n';
    import { HeadSet } from '@/utility';
    import { useHead } from '@vueuse/head';

    interface containerParameter {
        class?:string|null
    };

    const { t, locale } = useI18n();
    const property = defineProps<containerParameter>();
    const route = useRoute();
    const router = useRouter();
    const site = useGlobalStore();

    var componentInfo = ref({
        key:0,
        class:'',
        isLoaded:false
    });

    onMounted(async () => {
        componentInfo.value.class = property.class ?? '';

        const head = new HeadSet();
        head.title = `${t('common.notice')} - MuseStudio`;
        head.url = `${location.origin}/customer/notice`;
        useHead(head.CreateJson());

        componentInfo.value.isLoaded = true;

        setTimeout(() => {
            site.setValue('loading', false);
        }, 100);
    });

    const goContent = (content:BoardContent) => {
        document.location.href = `/customer/notice/${content.id}`;
    }
</script>

<style scoped>
ul.articleList li:last-child { border-bottom:none; }
</style>