import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, watch, computed } from 'vue';

// Props 및 Emits 정의

export default /*@__PURE__*/_defineComponent({
  __name: 'Toggle',
  props: {
modelValue: {
    type: Boolean,
    required: true
},
size: {
    type: String,
    default: 'md',
    validator: (value: string) => ['xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(value)
}
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

// 내부 상태 관리
const isActive = ref(false);

// 크기별 클래스 계산
const sizeClasses = computed(() => {
const sizes: Record<string, { wrapper: string; circle: string; translateActive: string; translateInactive: string }> = {
    xs: {
    wrapper: 'w-10 h-5',
    circle: 'w-4 h-4',
    translateActive: 'translate-x-5',
    translateInactive: 'translate-x-0'
    },
    sm: {
    wrapper: 'w-12 h-6',
    circle: 'w-5 h-5',
    translateActive: 'translate-x-6',
    translateInactive: 'translate-x-0'
    },
    md: {
    wrapper: 'w-16 h-8',
    circle: 'w-6 h-6',
    translateActive: 'translate-x-8',
    translateInactive: 'translate-x-0'
    },
    lg: {
    wrapper: 'w-20 h-10',
    circle: 'w-8 h-8',
    translateActive: 'translate-x-10',
    translateInactive: 'translate-x-0'
    },
    xl: {
    wrapper: 'w-24 h-12',
    circle: 'w-10 h-10',
    translateActive: 'translate-x-12',
    translateInactive: 'translate-x-0'
    },
    '2xl': {
    wrapper: 'w-28 h-14',
    circle: 'w-12 h-12',
    translateActive: 'translate-x-14',
    translateInactive: 'translate-x-0'
    }
};
return sizes[props.size] || sizes['md'];
});

// 초기값 설정
watch(
() => props.modelValue,
(newValue) => {
    isActive.value = newValue;
},
{ immediate: true }
);

// Toggle 상태 변경 함수
const toggle = () => {
isActive.value = !isActive.value;
emit('update:modelValue', isActive.value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
    sizeClasses.value.wrapper,
    'flex items-center rounded-full cursor-pointer transition-colors duration-300',
    isActive.value ? 'bg-green-500' : 'bg-gray-300'
    ]),
    onClick: toggle
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([
        sizeClasses.value.circle,
        'bg-white rounded-full shadow-md transform transition-transform duration-300',
        isActive.value ? sizeClasses.value.translateActive : sizeClasses.value.translateInactive
    ])
    }, null, 2)
  ], 2))
}
}

})