<template>
<div class="flex justify-between p-4 text-lg" :class="componentInfo.class">
    <div class="flex justify-start space-x-3">
        <div class="bg-yellow-600 w-[40px] h-[40px] thumbnail-img"><i :class="componentInfo.icon" class="mt-3 text-slate-100"></i></div>
        <h3 class="text-2xl mt-1 text-gray-500 dark:text-gray-100">{{ componentInfo.title }}</h3>
    </div>
    <div class="">
        <slot></slot>
    </div>
</div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

interface containerParameter {
    class?:string|null,
    icon?:string|null|undefined,
    title:string
};

const property = defineProps<containerParameter>();

var componentInfo = ref({
   class:'',
   title:'',
   icon:''
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.icon = property.icon ?? 'fa-solid fa-mug-hot';
    componentInfo.value.title = property.title;
});
</script>

<style scoped>
.thumbnail-img { border-radius: 50%; text-align: center; }
ul.row li:last-child { border:none; }
</style>