<template>
<GContainer>
  <header :key="`Header_${componentInfo.key}`">
    <nav class="relative container mx-auto p-6">
      <div class="flex item-center justify-between">
            <router-link to="/">
                <img src="/logo/Logo_Blue.png" alt="logo image" class="w-[146px] h-auto" />
            </router-link>
            <div class="flex space-x-6 text-gray-900 dark:text-gray-100 font-bold">
                <router-link to="/Intro" class="">회사소개</router-link>
                <router-link to="/History" class="">업력</router-link>
                <router-link to="/Service" class="">서비스</router-link>
                <router-link to="/customer" class="">고객센터</router-link>
            </div>
      </div>
    </nav>
  </header>
</GContainer>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n';
  import { GContainer,SearchField,ProfileButton,LogoutButton,RegistButton,LoginButton } from '@/components';
  import { useRouter } from 'vue-router';
  import { onMounted, ref, onUnmounted,watch } from 'vue';
  import { AuthService } from '@/services';
  import { useMemberStore } from '@/store';
  import { Member, MemberRepository, ValidateHelper } from '@gn2studio/gn2.library.common';
  import config from '@/config';
  import { MessageBox } from '@/utility';
  import DefaultButton from '../molecules/DefaultButton.vue';
  
  const { t, locale } = useI18n();
  const router = useRouter();
  const auth = new AuthService();
  const memberStore = useMemberStore();
  const currentPath = ref(location.pathname);
  const validate = new ValidateHelper();

  interface headerParameter {
    menu?:number|null|undefined
  };

  const property = defineProps<headerParameter>();

  var componentInfo = ref({
    key:0,
    menutab:0,
    member:null as Member|null,
    isShowSide:false,
    isLogin:false
  });

  onMounted(async () => {
    componentInfo.value.menutab = property.menu ?? 0;

    const accessToken = await auth.getAccessToken();

    if (accessToken !== null && accessToken !== undefined && String(accessToken).trim() !== '') {
      componentInfo.value.member = memberStore.member
      if (componentInfo.value.member === null 
      || componentInfo.value.member === undefined 
      || componentInfo.value.member.id === null 
      || componentInfo.value.member.id === undefined 
      || String(componentInfo.value.member.id).trim() === '')
      {
        const memberRep = new MemberRepository(config.apis.member, accessToken);
        const rst = await memberRep.GetUser();
        if (rst.check) {
          componentInfo.value.member = rst.data;
          
        } else {
          await auth.refreshSilent();
        }
        memberStore.setMember(rst.data);
      }

      componentInfo.value.isLogin = true;
      componentInfo.value.key += 1;
    }

    urlChange(location.pathname);

    window.addEventListener('popstate', () => {
      currentPath.value = location.pathname;
    });
  });

  onUnmounted(() => {
    window.removeEventListener('popstate', () => {
      currentPath.value = location.pathname;
    });
  });

  watch([currentPath, () => memberStore.member],
    ([newPath, newMember], [oldPath, oldMember]) => {
      if (newPath !== oldPath) {
        urlChange(newPath);
      }

      if (newMember !== oldMember) {
        componentInfo.value.member = memberStore.member;
        componentInfo.value.key += 1;
      }
    }
  );

  const fnLogin = () => {
    router.push(`/login?backurl=` + location.pathname);
  };
  
  const fnRegist = () => {
    router.push(`/regist`);
  };

  const fnLogout = () => {
    MessageBox.Confirm(t('system.logout_ment'), async () => {
      await auth.logout();
      memberStore.clearMember();
    });
  };

  const urlChange = (url:string) => {
    if (false === validate.IsNullOrWhiteSpace(url)) {
      if (url.indexOf('/') > -1) {
        if (url.split('/')[0].trim() === '') {
          url = url.split('/')[1].trim().toLowerCase();
        } else {
          url = url.split('/')[0].trim().toLowerCase();
        }
      }
    }

    switch (url) {
      case "content":
        componentInfo.value.menutab = 1;
        componentInfo.value.key += 1;
        break;
      case "album":
        componentInfo.value.menutab = 2;
        componentInfo.value.key += 1;
        break;
      case "customer":
        componentInfo.value.menutab = 3;
        componentInfo.value.key += 1;
        break;
    }
  };

  const fnSideMenu = () => {
    componentInfo.value.isShowSide = !componentInfo.value.isShowSide;
  };

  const fnProfile = () => {
    router.push(`/profile`);
  };
</script>
