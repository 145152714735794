<template>
<main :key="`content_list_${componentInfo.key}`" v-if="componentInfo.isLoaded" :class="componentInfo.class" class="w-full px-2">
    <div class="w-full mt-4 border-t border-slate-600 pt-4">
        <div class="w-full py-4 dark:text-gray-200">
            <div class="flex flex-col space-y-2">
                <div class="flex justify-start space-x-2">
                    <select class="rounded-md bg-gray-700 text-gray-200 px-2" v-model="componentInfo.data.category">
                        <option value="">제작유형을 선택하세요.</option>
                        <option value="AI 챗봇 서비스">AI 챗봇 서비스</option>
                        <option value="웹사이트 제작">웹사이트 제작</option>
                        <option value="기타 소프트웨어">기타 소프트웨어</option>
                    </select>
                    <GInput class="border border-gray-300 rounded-md text-gray-500 w-full" v-model="componentInfo.data.title" :placeholder="t('common.requiredtitlement')" />
                </div>
                <div class="flex justify-start space-x-2">
                    <GInput class="border border-gray-300 rounded-md text-gray-500 w-full" v-model="componentInfo.data.name" :placeholder="t('common.requiredName')" />
                    <GInput class="border border-gray-300 rounded-md text-gray-500 w-full" v-model="componentInfo.data.email" :placeholder="t('common.requiredEmail')" />
                </div>
                <div class="flex justify-start space-x-2">
                    <GInput class="border border-gray-300 rounded-md text-gray-500 w-full" v-model="componentInfo.data.phone" :placeholder="t('common.requiredPhone')" />
                    <GInput class="border border-gray-300 rounded-md text-gray-500 w-full" v-model="componentInfo.data.companyName" :placeholder="t('common.requiredCompanyName')" />
                </div>
            </div>
            <hr class="my-3 border-t border-gray-300 dark:border-gray-600" />
            <div class="p-1">
                <GTextbox class="border border-gray-300 rounded-md text-gray-500 w-full h-[400px]" v-model="componentInfo.data.content" :placeholder="t('common.requiredContent')"></GTextbox>
            </div>
        </div>
    </div>
    <div class="w-full my-3 flex justify-center space-x-2">
        <DefaultButton class="w-[120px]" type="accept" size="lg" icon="fa-solid fa-floppy-disk" @click="onSave">SAVE</DefaultButton>
        <DefaultButton class="w-[120px]" type="cancel" size="lg" icon="fa-solid fa-ban" @click="onCancel">CANCEL</DefaultButton>
    </div>
</main>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { useRoute,useRouter } from 'vue-router';
    import { GInput,DefaultButton,GTextbox } from '@/components';
    import { BoardRepository, Member, RequestFormDTO, ValidateHelper } from '@gn2studio/gn2.library.common';
    import dayjs from 'dayjs';
    import utc from 'dayjs/plugin/utc';
    import localizedFormat from 'dayjs/plugin/localizedFormat'; 
    import config from '@/config';
    import { useMemberStore } from '@/store';
    import { AuthService } from '@/services';
    import { MessageBox } from '@/utility';
    import { useI18n } from 'vue-i18n';
    
    dayjs.extend(utc);
    dayjs.extend(localizedFormat);
    
    interface containerParameter {
        class?:string|null
    };
    
    const { t, locale } = useI18n();
    const property = defineProps<containerParameter>();
    const route = useRoute();
    const router = useRouter();
    const auth = new AuthService();
    const memberStore = useMemberStore();
    const validate = new ValidateHelper();
    
    var boardRep = new BoardRepository(config.apis.board, '', config.global.slug);

    var componentInfo = ref({
        key:0,
        class:'',
        isLoaded:false,
        data: new RequestFormDTO(),
        member:null as null|Member,
        accessToken:'',
        isLoggedIn:false,
    });
    
    onMounted(async () => {
        componentInfo.value.class = property.class ?? '';
    
        memberStore.loadFromLocalStorage();
        let token = await auth.getAccessToken();
        if (token !== null && token !== undefined && String(token).trim() !== '') {
            componentInfo.value.accessToken = token;
            componentInfo.value.member = memberStore.member;
            boardRep = new BoardRepository(config.apis.board, token, config.global.slug);

            componentInfo.value.isLoggedIn = true;
        }
    
        componentInfo.value.isLoaded = true;
    });
    
    const onClear = () => {
        componentInfo.value.data = new RequestFormDTO();
    }
    
    const onSave = async () => {
        if (validate.IsNullOrWhiteSpace(componentInfo.value.data.content)) {
            MessageBox.Alert(`${t('system.required')} : ${t('common.content')}`);
        } else if (validate.IsNullOrWhiteSpace(componentInfo.value.data.title)) {
            MessageBox.Alert(`${t('system.required')} : ${t('common.title')}`);
        } else {
            let rst = await boardRep.RegistRequestForm(componentInfo.value.data);
            if (rst.check) {
                MessageBox.Success(t('common.Registered'), () => {
                    onClear();
                });
            } else {
                MessageBox.Alert(rst.message);
            }
        }
    };
    
    const onCancel = () => {
        document.location.reload();
    };
    
</script>
    