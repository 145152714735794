import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { HomeView } from '../views';
import { AuthCheck,AuthClear,AuthRefresh } from '../views';
import { NoticeList,CustomerHome,NoticeView,FAQ,BugReport,PartnerShip,RequestForm } from '../views';
import { Privacy,UserTerm } from '../views';
import { ProfileView,ProfileEditView } from '../views';
import { Login,Regist,Error } from '../views';
import { Intro,History,Service } from '../views';

const routes: Array<RouteRecordRaw> = [
  { path: '/signin-oidc',name: 'signin',component: AuthCheck },
  { path: '/signout-oidc',name: 'signout1',component: AuthClear },
  { path: '/signout-callback-oidc',name: 'signout2',component: AuthClear },
  { path: '/silent-renew-oidc',name: 'signrefresh',component: AuthRefresh },
  { path: '/', name: 'home',component: () => import('../layouts/Default.vue'), children: [ 
    { path:'/', component:HomeView },
  ] },
  { path: '/customer', name: 'customer',component: () => import('../layouts/Default.vue'), children: [ 
    { path:'/customer', component:CustomerHome },
    { path:'/customer/bug', component:BugReport },
    { path:'/customer/partner', component:PartnerShip },
    { path:'/customer/notice', component:NoticeList },
    { path:'/customer/faq', component:FAQ },
    { path:'/customer/notice/:id', component:NoticeView },
    { path:'/request/form', component:RequestForm },
  ] },
  { path: '/profile', name: 'profile',component: () => import('../layouts/Default.vue'), children: [ 
    { path:'/profile', component:ProfileView },
    { path:'/profile/edit', component:ProfileEditView },
    { path:'/profile/:id', component:ProfileView },
  ] },
  { path: '/login', name: 'member',component: () => import('../layouts/Empty.vue'), children: [ 
    { path:'/login', component:Login },
    { path:'/regist', component:Regist },
  ] },
  { path: '/error', name: 'error',component: () => import('../layouts/Empty.vue'), children: [ 
    { path:'/error/:code', component:Error },
  ] },
  { path: '/document', name: 'document',component: () => import('../layouts/Default.vue'), children: [ 
    { path:'/document/term', component:UserTerm },
    { path:'/document/privacy', component:Privacy },
  ] },
  { path: '/sub', name: 'sub',component: () => import('../layouts/Default.vue'), children: [ 
    { path:'/Intro', component:Intro },
    { path:'/History', component:History },
    { path:'/Service', component:Service },
  ] },
]

const router = createRouter({
  history: createWebHistory(""),
  routes
})

export default router
