import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full bg-blue-950 dark:bg-blue-900 text-white p-3 mb-2" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "w-full px-2" }

import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { BoardContent, BoardRepository } from '@gn2studio/gn2.library.common';
import config from '@/config';
import { useI18n } from 'vue-i18n';

interface boardParameter {
    code:string,
    class?:string|null|undefined,
    clickEvent?:Function|null|undefined,
    url?:string|null|undefined
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BoardBox',
  props: {
    code: {},
    class: {},
    clickEvent: { type: [Function, null] },
    url: {}
  },
  setup(__props: any) {

const { t, locale } = useI18n();
const property = __props;
const router = useRouter();
const boardRep = new BoardRepository(config.apis.board, '', config.global.slug);

var componentInfo = ref({
    class:"",
    code:"",
    items:[] as BoardContent[],
    isLoaded:false,
    key:0,
    url:''
});

onMounted(async () => {
    componentInfo.value.code = property.code;
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.url = (property.url !== null && property.url !== undefined) ? property.url : '#';

    await getNotice();

    componentInfo.value.isLoaded = true;
});

const getNotice = async () => {
  let rst = await boardRep.GetBoards(componentInfo.value.code, { curPage : 1, pageSize : 5, keyword:'' });
  if (rst.check) {
    componentInfo.value.items = rst.data;
    componentInfo.value.key += 1;
  } else {
    console.log('getNotice error : ', rst.message);
  }
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["w-full border rounded-xl overflow-hidden border-gray-400 dark:border-gray-800 pb-2", _unref(componentInfo).class]),
        key: `notice_${_unref(componentInfo).key}`
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createTextVNode(_toDisplayString(_unref(t)(`common.${_unref(componentInfo).code}`)) + " ", 1),
          _createVNode(_component_router_link, {
            to: _unref(componentInfo).url,
            class: "mini float-right mt-1 mr-1"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("more")
            ])),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(componentInfo).items, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: `notice_${index}`,
              class: "mb-2 pb-2 w-full notice-item border-gray-100 dark:border-gray-600 border-dashed last-hide"
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_router_link, {
                  to: `${_unref(componentInfo).url}/${item.id}`,
                  class: "text-xs font-medium text-gray-600 dark:text-gray-200"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ])
            ]))
          }), 128))
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})