<template>
<GButton class="border flex items-center justify-center" v-if="componentInfo.isLoaded" :size="componentInfo.size" :class="componentInfo.typeClass" :key="`defaultbutton_${componentInfo.key}`">
    <i class="mr-1" :class="componentInfo.icon" v-if="componentInfo.icon !== null && componentInfo.icon !== undefined && String(componentInfo.icon).trim() !== ''"></i>
    <div :class="componentInfo.slotClass">
        <slot></slot>
    </div>
</GButton>

</template>

<script setup lang="ts">
import { onMounted, ref,watch } from 'vue';
import GButton from '../atoms/GButton.vue';

interface buttonParameter {
    class?:string|null|undefined,
    icon?:string|null|undefined,
    size?:string|null|undefined,
    type:'default'|'accept'|'warning'|'danger'|'info'|'cancel'
};

const property = defineProps<buttonParameter>();

var componentInfo = ref({
    key:0,
    isLoaded:false,
    class:'',
    size:'',
    icon:'',
    typeClass:'',
    slotClass:''
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.size = property.size ?? 'md';
    componentInfo.value.icon = property.icon ?? '';
    setClass();
    componentInfo.value.isLoaded = true;
});

watch(() => property,
    (newValue) => {
        componentInfo.value.size = (newValue.size !== null && newValue.size !== undefined) ? newValue.size : '';
        componentInfo.value.class = (newValue.class !== null && newValue.class !== undefined) ? newValue.class : '';
        componentInfo.value.icon = (newValue.icon !== null && newValue.icon !== undefined) ? newValue.icon : '';
        setClass();
        componentInfo.value.key += 1;
    }
);

const setClass = () => {
    switch (property.type) {
        case 'default':
            componentInfo.value.typeClass += `${componentInfo.value.class} bg-slate-200 dark:bg-slate-700 border-slate-400 dark:border-slate-600 shadow-md text-gray-600 dark:text-gray-300 hover:bg-slate-900 hover:text-gray-100 dark:hover:bg-slate-900 dark:hover:text-gray-100`;
            break;
        case 'accept':
            componentInfo.value.typeClass += `${componentInfo.value.class} bg-green-800 border-green-700 shadow-md text-gray-200 hover:bg-green-500 hover:text-gray-100`;
            break;
        case 'warning':
            componentInfo.value.typeClass += `${componentInfo.value.class} bg-yellow-500 border-yellow-400 text-gray-100 dark:bg-yellow-300 dark:border-yellow-100 shadow-md dark:text-gray-800 hover:bg-yellow-600 hover:text-gray-200 hover:border-yellow-400 dark:hover:bg-yellow-600 dark:hover:text-gray-200 dark:hover:border-yellow-700`;
            break;
        case 'danger':
            componentInfo.value.typeClass += `${componentInfo.value.class} bg-red-700 border-red-700 shadow-md text-gray-200 hover:bg-red-500 hover:text-gray-100`;
            break;
        case 'info':
            componentInfo.value.typeClass += `${componentInfo.value.class} bg-cyan-700 border-cyan-700 shadow-md text-gray-200 hover:bg-cyan-500 hover:text-gray-100`;
            break;
        case 'cancel':
            componentInfo.value.typeClass += `${componentInfo.value.class} bg-sky-400 border-sky-300 shadow-md text-gray-200 hover:bg-sky-300 hover:text-gray-100`;
            break;
    }

    switch (property.size) {
        case "xs":
            componentInfo.value.typeClass += `${componentInfo.value.typeClass} min-w-[35px] h-[30px] px-2`;
            componentInfo.value.slotClass += `mt-0.5`;
            break;
        case "sm":
            componentInfo.value.typeClass += `${componentInfo.value.typeClass} min-w-[40px] h-[32px] px-2`;
            componentInfo.value.slotClass += `mt-0.5`;
            break;
        case "md":
            componentInfo.value.typeClass += `${componentInfo.value.typeClass} min-w-[45px] h-[34px] px-3`;
            componentInfo.value.slotClass += ``;
            break;
        case "lg":
            componentInfo.value.typeClass += `${componentInfo.value.typeClass} min-w-[50px] h-[36px] px-3`;
            componentInfo.value.slotClass += ``;
            break;
        case "xl":
            componentInfo.value.typeClass += `${componentInfo.value.typeClass} min-w-[55px] h-[38px] px-3`;
            componentInfo.value.slotClass += ``;
            break;
        case "2xl":
            componentInfo.value.typeClass += `${componentInfo.value.typeClass} min-w-[60px] h-[40px] px-3`;
            componentInfo.value.slotClass += ``;
            break;
    }
};

</script>