import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "text-9xl font-bold text-blue-500" }
const _hoisted_4 = { class: "mt-4 text-3xl font-semibold" }
const _hoisted_5 = { class: "mt-2 text-lg text-gray-500 dark:text-gray-400" }

import { computed,onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGlobalStore } from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'Error',
  setup(__props) {

const site = useGlobalStore();
const route = useRoute();
const router = useRouter();
const errorDetails = computed(() => {
const code = String(route.params.code || "404"); // 문자열로 변환
const errorMap: Record<string, { message: string; description: string }> = {
    "400": {
      message: "Bad Request",
      description: "The request could not be understood by the server.",
    },
    "401": {
      message: "Unauthorized",
      description: "You need to log in to access this page.",
    },
    "403": {
      message: "Forbidden",
      description: "You do not have permission to access this page.",
    },
    "404": {
      message: "Page Not Found",
      description: "The page you are looking for doesn't exist.",
    },
    "500": {
      message: "Internal Server Error",
      description: "Something went wrong on our end.",
    },
  };

  // 에러 코드가 정의되지 않은 경우 404 반환
  return errorMap[code] || errorMap["404"];
  });

const goHome = () => {
router.push("/");
};

onMounted(() => {
  setTimeout(() => {
      site.setValue('loading', false);
  }, 100);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_unref(route).params.code || "404"), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(errorDetails.value.message), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(errorDetails.value.description), 1),
      _createElementVNode("button", {
        onClick: goHome,
        class: "mt-8 px-6 py-3 bg-blue-500 hover:bg-blue-600 text-white rounded-md text-lg shadow-lg"
      }, " Back to Home ")
    ])
  ]))
}
}

})