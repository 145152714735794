import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full mt-4 border-t border-slate-600 pt-4" }
const _hoisted_2 = { class: "w-full py-4 dark:text-gray-200" }
const _hoisted_3 = { class: "flex flex-col space-y-2" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "flex justify-start space-x-2" }
const _hoisted_6 = { class: "flex justify-start space-x-2" }
const _hoisted_7 = { class: "p-1" }
const _hoisted_8 = { class: "w-full my-3 flex justify-center space-x-2" }

import { onMounted, ref } from 'vue';
    import { useRoute,useRouter } from 'vue-router';
    import { GInput,DefaultButton,GTextbox } from '@/components';
    import { BoardRepository, Member, Partner, ValidateHelper } from '@gn2studio/gn2.library.common';
    import dayjs from 'dayjs';
    import utc from 'dayjs/plugin/utc';
    import localizedFormat from 'dayjs/plugin/localizedFormat'; 
    import config from '@/config';
    import { useMemberStore } from '@/store';
    import { AuthService } from '@/services';
    import { MessageBox } from '@/utility';
    import { useI18n } from 'vue-i18n';
    
    interface containerParameter {
        class?:string|null
    }
export default /*@__PURE__*/_defineComponent({
  __name: 'PartnerShipPage',
  props: {
    class: {}
  },
  setup(__props: any) {

    dayjs.extend(utc);
    dayjs.extend(localizedFormat);
    
    ;
    
    const { t, locale } = useI18n();
    const property = __props;
    const route = useRoute();
    const router = useRouter();
    const auth = new AuthService();
    const memberStore = useMemberStore();
    const validate = new ValidateHelper();
    
    var boardRep = new BoardRepository(config.apis.board, '', config.global.slug);

    var componentInfo = ref({
        key:0,
        class:'',
        isLoaded:false,
        data: new Partner(),
        member:null as null|Member,
        accessToken:'',
        isLoggedIn:false,
    });
    
    onMounted(async () => {
        componentInfo.value.class = property.class ?? '';
    
        memberStore.loadFromLocalStorage();
        let token = await auth.getAccessToken();
        if (token !== null && token !== undefined && String(token).trim() !== '') {
            componentInfo.value.accessToken = token;
            componentInfo.value.member = memberStore.member;
            boardRep = new BoardRepository(config.apis.board, token, config.global.slug);

            componentInfo.value.isLoggedIn = true;
        }
    
        componentInfo.value.isLoaded = true;
    });
    
    const onClear = () => {
        componentInfo.value.data = new Partner();
    }
    
    const onSave = async () => {
        if (validate.IsNullOrWhiteSpace(componentInfo.value.data.content)) {
            MessageBox.Alert(`${t('system.required')} : ${t('common.content')}`);
        } else if (validate.IsNullOrWhiteSpace(componentInfo.value.data.title)) {
            MessageBox.Alert(`${t('system.required')} : ${t('common.title')}`);
        } else {
            let rst = await boardRep.RegistPartner(componentInfo.value.data);
            if (rst.check) {
                MessageBox.Success(t('common.Registered'), () => {
                    onClear();
                });
            } else {
                MessageBox.Alert(rst.message);
            }
        }
    };
    
    const onCancel = () => {
        document.location.reload();
    };
    

return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createElementBlock("main", {
        key: `content_list_${_unref(componentInfo).key}`,
        class: _normalizeClass([_unref(componentInfo).class, "w-full px-2"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_unref(GInput), {
                  class: "border border-gray-300 rounded-md text-gray-500 w-full",
                  modelValue: _unref(componentInfo).data.title,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(componentInfo).data.title) = $event)),
                  placeholder: _unref(t)('common.requiredtitlement')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_unref(GInput), {
                  class: "border border-gray-300 rounded-md text-gray-500 w-full",
                  modelValue: _unref(componentInfo).data.name,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(componentInfo).data.name) = $event)),
                  placeholder: _unref(t)('common.requiredName')
                }, null, 8, ["modelValue", "placeholder"]),
                _createVNode(_unref(GInput), {
                  class: "border border-gray-300 rounded-md text-gray-500 w-full",
                  modelValue: _unref(componentInfo).data.email,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(componentInfo).data.email) = $event)),
                  placeholder: _unref(t)('common.requiredEmail')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_unref(GInput), {
                  class: "border border-gray-300 rounded-md text-gray-500 w-full",
                  modelValue: _unref(componentInfo).data.phone,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(componentInfo).data.phone) = $event)),
                  placeholder: _unref(t)('common.requiredPhone')
                }, null, 8, ["modelValue", "placeholder"]),
                _createVNode(_unref(GInput), {
                  class: "border border-gray-300 rounded-md text-gray-500 w-full",
                  modelValue: _unref(componentInfo).data.companyName,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(componentInfo).data.companyName) = $event)),
                  placeholder: _unref(t)('common.requiredCompanyName')
                }, null, 8, ["modelValue", "placeholder"])
              ])
            ]),
            _cache[6] || (_cache[6] = _createElementVNode("hr", { class: "my-3 border-t border-gray-300 dark:border-gray-600" }, null, -1)),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_unref(GTextbox), {
                class: "border border-gray-300 rounded-md text-gray-500 w-full h-[400px]",
                modelValue: _unref(componentInfo).data.content,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(componentInfo).data.content) = $event)),
                placeholder: _unref(t)('common.requiredContent')
              }, null, 8, ["modelValue", "placeholder"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_unref(DefaultButton), {
            class: "w-[120px]",
            type: "accept",
            size: "lg",
            icon: "fa-solid fa-floppy-disk",
            onClick: onSave
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("SAVE")
            ])),
            _: 1
          }),
          _createVNode(_unref(DefaultButton), {
            class: "w-[120px]",
            type: "cancel",
            size: "lg",
            icon: "fa-solid fa-ban",
            onClick: onCancel
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode("CANCEL")
            ])),
            _: 1
          })
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})