<template>
    <div class="w-full border rounded-xl overflow-hidden border-gray-400 dark:border-gray-800 pb-2" v-if="componentInfo.isLoaded" :key="`notice_${componentInfo.key}`" :class="componentInfo.class">
        <div class="w-full bg-blue-950 dark:bg-blue-900 text-white p-3 mb-2">
            {{ t(`common.${componentInfo.code}`) }}
            <router-link :to="componentInfo.url" class="mini float-right mt-1 mr-1">more</router-link>
        </div>
        <ul class="w-full">
            <li v-for="(item,index) in componentInfo.items" :key="`notice_${index}`" class="mb-2 pb-2 w-full notice-item border-gray-100 dark:border-gray-600 border-dashed last-hide">
                <div class="w-full px-2">
                    <router-link :to="`${componentInfo.url}/${item.id}`" class="text-xs font-medium text-gray-600 dark:text-gray-200">{{ item.title }}</router-link>
                </div>
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { BoardContent, BoardRepository } from '@gn2studio/gn2.library.common';
import config from '@/config';
import { useI18n } from 'vue-i18n';

interface boardParameter {
    code:string,
    class?:string|null|undefined,
    clickEvent?:Function|null|undefined,
    url?:string|null|undefined
}

const { t, locale } = useI18n();
const property = defineProps<boardParameter>();
const router = useRouter();
const boardRep = new BoardRepository(config.apis.board, '', config.global.slug);

var componentInfo = ref({
    class:"",
    code:"",
    items:[] as BoardContent[],
    isLoaded:false,
    key:0,
    url:''
});

onMounted(async () => {
    componentInfo.value.code = property.code;
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.url = (property.url !== null && property.url !== undefined) ? property.url : '#';

    await getNotice();

    componentInfo.value.isLoaded = true;
});

const getNotice = async () => {
  let rst = await boardRep.GetBoards(componentInfo.value.code, { curPage : 1, pageSize : 5, keyword:'' });
  if (rst.check) {
    componentInfo.value.items = rst.data;
    componentInfo.value.key += 1;
  } else {
    console.log('getNotice error : ', rst.message);
  }
};
</script>

<style scoped>
.notice-item { border-bottom-width:1px; }
.notice-item:last-child { border-bottom-width:none; }
.mini { font-size:10px;font-family:돋움, 돋움체; }
</style>
