<template>
<GContainer>
<section id="hero">
  <div class="container flex flex-col-reverse lg:flex-row item-center px-2 mt-10 space-y-0 lg:space-y-0 text-gray-900 dark:text-gray-100">
        <!-- left item -->
        <div class="flex flex-col mb-32 space-y-12 lg:w-1/2">
          <div class="block lg:hidden h-1"></div>
            <h1 class="max-w-md text-4xl font-bold text-center lg:text-5xl lg:text-left">
                  RAG기반 AI Chatbot
            </h1>
            <p class="max-w-sm text-center text-darkGrayisBlue lg:text-left">
                GPT 모델에 RAG를 이용해서 고객사에 Database 또는 자료를 토대로 대화하는 챗봇을 구축하세요.
                <br/>
                <br/>
                Azure 기반 서비스 구축 및 챗봇 구축, 벡터 검색 등 필요한 인프라 셋팅부터 서비스 구축까지 한번에 해결해 드립니다.
            </p>
            <div class="flex justify-center lg:justify-start">
                  <router-link to="/request/form" class="py-3 px-6 text-white bg-blue-800 rounded-full baseline 
                  hover:bg-blue-500 Light">{{ t('common.question') }}</router-link>
            </div>
        </div>
        <!-- Images -->
        <div class="lg:w-1/2">
            <img src="/images/thumbnail/ai.jpg" class="w-[99%] h-auto m-auto overflow-hidden rounded-lg shadow-md" alt="left-flex-image-hero-section" />
        </div>
  </div>
</section>
<!-- features section -->
<section id="features">
  <!-- Flex container -->
  <div class="container flex flex-col px-4 mx-auto mt-10 space-y-12 lg:space-y-0 lg:flex-row text-gray-900 dark:text-gray-100">
    <!-- What's Different -->
    <div class="flex flex-col space-y-12 lg:w-1/2">
      <h2 class="max-w-md text-4xl font-bold text-center lg:text-left" style="line-height: 160%;">
        <span class="py-1 px-3 bg-blue-200 dark:bg-blue-800 rounded-full mx-1">GN2Studio</span>에서 무엇을 할 수 있나요?
      </h2>
      <p class="max-w-sm text-center lg:text-left">
        당사는 소프트웨어 솔루션 공급 및 개발, 인터넷 웹서비스를 전문적으로 제공하는 회사입니다.
        특히 인프라 서비스 운영 대행 및 컨설팅을 통한 솔루션 구축에 특화되어 있습니다.
      </p>
    </div>

    <!-- Numbered List -->
    <div class="flex flex-col space-y-8 lg:w-1/2">
      <!-- List Item 1 -->
      <div class="flex flex-col space-y-3 lg:space-y-0 lg:space-x-6 lg:flex-row">
        <!-- Heading -->
        <div class="rounded-l-full bg-blue-500 lg:bg-transparent">
          <div class="flex items-center space-x-2">
            <div class="p-2 text-white rounded-full lg:py-1 bg-blue-500 ">01</div>
            <h3 class="text-base font-bold lg:mb-4 lg:hidden">
              RAG 기반 AI 챗봇 구축
            </h3>
          </div>
        </div>

        <div>
          <h3 class="hidden mb-4 text-lg font-bold lg:block">
            RAG 기반 AI 챗봇 구축
          </h3>
          <p class="text-gray-700 dark:text-gray-300">
            Azure 기반 AI 모델을 이용한 RAG 기능을 가진 챗봇을 구축해 드립니다.
            또한 Azure AI Search 기반에 벡터검색을 통해 강화된 서비스 제공이 가능합니다.
          </p>
        </div>
      </div>

      <!-- List Item 2 -->
      <div class="flex flex-col space-y-3 lg:space-y-0 lg:space-x-6 lg:flex-row">
        <!-- Heading -->
        <div class="rounded-l-full bg-blue-500  lg:bg-transparent">
          <div class="flex items-center space-x-2">
            <div class="p-2 text-white rounded-full lg:py-1 bg-blue-500 ">02</div>
            <h3 class="text-base font-bold lg:mb-4 lg:hidden">
              기능성 홈페이지 제작
            </h3>
          </div>
        </div>

        <div>
          <h3 class="hidden mb-4 text-lg font-bold lg:block">
            기능성 홈페이지 제작
          </h3>
          <p class="text-gray-700 dark:text-gray-300">
            예약 기능, 기기 연결 서비스(프린터 등), 모바일 등 어플리케이션 연결 서비스 등
            상황과 비즈니스에 따라 다양한 웹서비스를 구축해 드립니다.
          </p>
        </div>
      </div>

      <!-- List Item 3 -->
      <div class="flex flex-col space-y-3 lg:space-y-0 lg:space-x-6 lg:flex-row">
        <!-- Heading -->
        <div class="rounded-l-full bg-blue-500  lg:bg-transparent">
          <div class="flex items-center space-x-2">
            <div class="p-2 text-white rounded-full lg:py-1 bg-blue-500">03</div>
            <h3 class="text-base font-bold lg:mb-4 lg:hidden">
              백그라운드 서비스 및 웹크롤러
            </h3>
          </div>
        </div>

        <div>
          <h3 class="hidden mb-4 text-lg font-bold lg:block">
            백그라운드 서비스 및 웹크롤러
          </h3>
          <p class="text-gray-700 dark:text-gray-300">
            주기적으로 동작하는 백그라운드 프로그램(결산, 정산, 집계 등) 및
            웹사이트 정보 수집을 위한 웹크롤러등을 제작해 드립니다.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
</GContainer>
<section id="cta" class="bg-blue-500 my-12">
  <GContainer>
  <div class="container flex flex-col items-center justify-between px-6 py-24 mx-auto space-y-12 lg:py-12 lg:flex-row lg:space-y-0">
    <h2 class="text-5xl font-bold text-center text-white lg:text-4xl lg:max-w-xl lg:text-left">
      지금 바로 제작 문의하세요.
    </h2>
    <div>
      <router-link to="/request/form" class="py-3 px-6 text-gray-800 bg-white rounded-full shadow-lg baseline hover:bg-gray-900 hover:text-gray-200">
        {{ t('common.Inquiry') }}
      </router-link>
    </div>
  </div>
</GContainer>
</section>
<GContainer>
<section id="testimonials">
  <div class="max-w-6xl px-5 mx-auto mt-32 text-center text-gray-900 dark:text-gray-100">
        <h2 class="text-4xl font-bold text-center">
            다양한 서비스를 제공하고 있습니다.
        </h2>
        <div class="flex flex-col mt-24 lg:flex-row lg:space-x-6">
            <div class="flex flex-col items-center p-6 space-y-6 rounded-lg bg-gray-200 dark:bg-slate-800 lg:w-1/3">
              <a href="https://muse.gn2studio.com" target="_blank">
                  <GImage src="/logo/favicon.png" width="64px" height="64px" class="w-16 -mt-14 rounded-full shadow-lg m-auto" alt="Muse Studio" />
                  <h5 class="text-lg font-bold">Muse Studio</h5>
                  <p class="text-sm text-darkGrayisBlue">
                      “{{ t('site.welcomement') }}”
                  </p>
                </a>
            </div>
            
            <div class="hidden flex-col items-center p-6 space-y-6 rounded-lg bg-gray-200 dark:bg-slate-800 lg:flex lg:w-1/3">
              <a href="https://www.merrytoktok.com" target="_blank">
                  <GImage src="/logo/merrytoktok.png" width="64px" height="64px" class="w-16 -mt-14 rounded-full shadow-lg m-auto" alt="MerryTokTok" />
                  <h5 class="text-lg font-bold">MerryTokTok</h5>
                  <p class="text-sm text-darkGrayisBlue">
                  “다양한 이야기를 나눌 수 있는 SPA 기반 커뮤니케이션 서비스입니다. 언제든지 즐겁고 유쾌한 대화를 시작하세요.”
                  </p>
                </a>
            </div>
            <div class="hidden flex-col items-center p-6 space-y-6 rounded-lg bg-gray-200 dark:bg-slate-800 lg:flex lg:w-1/3">
                  <div src="#" class="w-16 h-16 -mt-14 bg-gray-700 rounded-full shadow-lg">&nbsp;</div>
                  <h5 class="text-lg font-bold">준비중입니다.</h5>
                  <p class="text-sm text-darkGrayisBlue">
                  “새로운 서비스를 제공하기 위해 노력하고 있습니다.”
                  </p>
            </div>

        </div>
        <div class="my-16">
            <router-link to="/Service" class="py-2 px-4 text-white bg-blue-500  rounded-full baseline hover:bg-blue-500 Light">
                  더 많은 서비스 찾아보기
            </router-link>
        </div>
  </div>
</section>
</GContainer>

</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { AuthService } from '@/services';
import { useGlobalStore } from '@/store';
import { GContainer, GImage } from '@/components';
import { useI18n } from 'vue-i18n';

const auth = new AuthService();
const site = useGlobalStore();
const { t, locale } = useI18n();

var componentInfo = ref({
    key:0,
    class:'',
    isLoaded:false
});

onMounted(async () => {
  componentInfo.value.isLoaded = true;

  setTimeout(() => {
    site.setValue('loading', false);
  }, 200);
});
</script>
