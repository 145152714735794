<template>
    <div :key="`popup_${componentInfo.key}`" class="popup flex justify-center items-center" v-show="componentInfo.isShow">
        <div :class="componentInfo.class" class="relative bg-gray-100 dark:bg-gray-600 p-5 rounded-xl shadow-xl shadow-black border border-gray-500 dark:border-gray-700">
            <button type="button" class="absolute popupBtn" @click="closePopup">
                <img src="/images/close-button.png" alt="close" />
            </button>
            <slot></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, toRef, watch } from 'vue';

interface popupParameter {
    class?: string | null;
    mode?: 'default' | 'full' | null | undefined;
    modelValue: boolean; // v-model의 기본 prop 이름
}

const property = defineProps<popupParameter>();
const emit = defineEmits(['update:modelValue']); // v-model 이벤트 이름

const isShowRef = toRef(property, 'modelValue');

const componentInfo = ref({
    key: 0,
    class: '',
    mode: 'default' as 'default' | 'full',
    isShow: false
});

// 반응형 데이터 초기화
componentInfo.value.class = property.class ?? '';
componentInfo.value.mode = property.mode ?? 'default';
componentInfo.value.isShow = property.modelValue;

// 부모로부터 전달된 값 감지
watch(isShowRef, (newValue) => {
    componentInfo.value.isShow = newValue;
});

// 값 변경 시 부모에게 업데이트 알림
watch(() => componentInfo.value.isShow, (newValue) => {
    emit('update:modelValue', newValue); // 부모 컴포넌트로 데이터 전달
});

// 팝업 닫기
const closePopup = () => {
    componentInfo.value.isShow = false;
};
</script>

<style scoped>
.popup {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
}
.popupBtn {
    top: -10px;
    right: -10px;
}
</style>
