import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  viewBox: "0 0 20 20",
  class: "w-full h-full fill-current"
}
const _hoisted_3 = {
  key: 1,
  class: "w-full h-full fill-current animate-spin",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

import { ref, watch, onMounted } from 'vue';

// prop 정의

export default /*@__PURE__*/_defineComponent({
  __name: 'SmileButton',
  props: {
  modelValue: {
    type: Boolean,
    required: true
  }
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const props = __props;

// 이벤트 정의
const emit = __emit;

const isLoading = ref(props.modelValue);
const componentInfo = ref({
  class: '',
  key: 0,
  isLoaded: false,
  isLoading: false
});

onMounted(() => {
  componentInfo.value.isLoaded = true;
});

// 컴포넌트 로딩 상태 업데이트
watch(() => props.modelValue, (newVal) => {
  isLoading.value = newVal;
});

return (_ctx: any,_cache: any) => {
  return (componentInfo.value.isLoaded)
    ? (_openBlock(), _createElementBlock("button", {
        type: "button",
        disabled: isLoading.value,
        key: componentInfo.value.key,
        class: "absolute top-0 right-0 mt-2 mr-3 flex flex-shrink-0 focus:outline-none text-blue-600 hover:text-blue-700 w-6 h-6"
      }, [
        (!isLoading.value)
          ? (_openBlock(), _createElementBlock("svg", _hoisted_2, _cache[0] || (_cache[0] = [
              _createElementVNode("path", { d: "M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM6.5 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm7 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm2.16 3a6 6 0 0 1-11.32 0h11.32z" }, null, -1)
            ])))
          : _createCommentVNode("", true),
        (isLoading.value)
          ? (_openBlock(), _createElementBlock("svg", _hoisted_3, _cache[1] || (_cache[1] = [
              _createStaticVNode("<defs data-v-2e85e638><linearGradient id=\"gradient\" x1=\"0%\" y1=\"0%\" x2=\"100%\" y2=\"0%\" data-v-2e85e638><stop offset=\"0%\" stop-color=\"#3B82F6\" data-v-2e85e638></stop><stop offset=\"50%\" stop-color=\"#06B6D4\" data-v-2e85e638></stop><stop offset=\"100%\" stop-color=\"#3B82F6\" data-v-2e85e638></stop></linearGradient></defs><circle cx=\"12\" cy=\"12\" r=\"10\" stroke=\"url(#gradient)\" stroke-width=\"4\" fill=\"none\" stroke-dasharray=\"62.8319\" stroke-dashoffset=\"0\" data-v-2e85e638></circle>", 2)
            ])))
          : _createCommentVNode("", true)
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}
}

})