<template>
  <footer class="bg-slate-950 mt-12">
    <GContainer>
        <!-- Flex Container -->
        <div class="container flex flex-col-reverse justify-between px-6 py-10 mx-auto space-y-8 lg:flex-row lg:space-y-0">
        <!-- Logo and social links container -->
          <div class="flex flex-col-reverse items-center justify-between space-y-12 lg:flex-col lg:space-y-0 lg:items-start">
          <!-- Logo -->
            <router-link to="/">
              <img src="/logo/Logo_Blue.png" alt="logo image" class="w-[146px] h-auto filter invert brightness-0" />
            </router-link>
            <div class=""></div>
          </div>
        <!-- List Container -->
          <div class="flex justify-around space-x-32">
            <div class="flex flex-col space-y-3 text-white">
                <router-link to="/" class="hover:text-gray-100">Home</router-link>
                <router-link to="/Intro" class="hover:text-gray-100">회사소개</router-link>
                <router-link to="/History" class="hover:text-gray-100">서비스</router-link>
                <router-link to="/Service" class="hover:text-gray-100">업력</router-link>
            </div>
            <div class="flex flex-col space-y-3 text-white">
                <a href="https://muse.gn2studio.com" target="_blank" class="hover:text-gray-100">뮤즈스튜디오</a>
                <a href="https://www.merrytoktok.com" target="_blank" class="hover:text-gray-100">메리톡톡</a>
                <router-link to="/customer" class="hover:text-gray-100">고객센터</router-link>
            </div>
          </div>

          <div class="flex flex-col justify-between">
            <div></div>
            <div class="flex justify-center space-x-4 text-white">
                <a :href="config.link.facebook" target="_blank">
                  <i class="fa-brands fa-facebook"></i>
                </a>
                <a :href="config.link.youtube" target="_blank">
                  <i class="fa-brands fa-youtube"></i>
                </a>
                <a :href="config.link.blog" target="_blank">
                  <i class="fa-solid fa-blog"></i>
                </a>
            </div>
          </div>
        </div>
      </GContainer>
  </footer>
  <footer class="bg-slate-700" :class="componentInfo.class">
    <GContainer>
        <GBetweenSide class="p-2">
            <template #left>
                <div class="text-gray-300 mt-2">©{{ componentInfo.year }} <span>gn2studio.com</span>. All rights reserved.</div>
            </template>
            <template #right>
              <div class="flex justify-start lg:justify-end space-x-5 text-white mt-1">
                  <router-link to="/document/privacy">{{ t('system.Privacy') }}</router-link>
                  <router-link to="/document/term">{{ t('system.Terms') }}</router-link>
                </div>
            </template>
        </GBetweenSide>
    </GContainer>
  </footer>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { GContainer,GBetweenSide } from '../atoms';
import { ValidateHelper } from '@gn2studio/gn2.library.common';
import router from '@/router';
import config from '@/config';
import { useI18n } from 'vue-i18n';

interface containerParameter {
    class?:string|null
};

const { t, locale } = useI18n();
const property = defineProps<containerParameter>();
const validate = new ValidateHelper();

var componentInfo = ref({
    year:0,
    class:'',
    documentUrl:'',
    linkUrl:''
});

onMounted(() => {
    let dt = new Date();
    componentInfo.value.year = dt.getFullYear();
    componentInfo.value.class = property.class ?? '';
});

const fnInnerUrl = () => {
    if (false === validate.IsNullOrWhiteSpace(componentInfo.value.documentUrl)) {
        router.push(componentInfo.value.documentUrl);
    }
};

const fnOuterUrl = () => {
    if (false === validate.IsNullOrWhiteSpace(componentInfo.value.linkUrl)) {
        window.open(componentInfo.value.linkUrl);
    }
};
</script>