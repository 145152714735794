import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "about-us",
  class: "bg-gray-50 dark:bg-gray-800 py-12 rounded-lg"
}
const _hoisted_2 = { class: "container mx-auto px-6 text-gray-900 dark:text-gray-100" }
const _hoisted_3 = { class: "flex flex-col lg:flex-row items-center lg:items-start space-y-12 lg:space-y-0 lg:space-x-12" }
const _hoisted_4 = { class: "lg:w-1/2" }

import { GContainer, GImage } from '@/components';
import { useGlobalStore } from '@/store';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'Intro',
  setup(__props) {

const site = useGlobalStore();
const { t, locale } = useI18n();

onMounted(async () => {
    setTimeout(() => {
        site.setValue('loading', false);
    }, 100);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(GContainer), null, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-center mb-12" }, [
            _createElementVNode("h1", { class: "text-4xl font-bold" }, "회사소개"),
            _createElementVNode("p", { class: "mt-4 text-gray-600 dark:text-gray-300" }, "GN2Studio는 최고의 기술력과 서비스를 제공하기 위해 노력합니다.")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_unref(GImage), {
                src: "/images/bg/cardBg.jpg",
                class: "w-full h-80 rounded-lg shadow-lg",
                alt: ""
              })
            ]),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "lg:w-1/2 space-y-6" }, [
              _createElementVNode("h2", { class: "text-3xl font-bold" }, "우리의 목표"),
              _createElementVNode("p", { class: "text-gray-700 dark:text-gray-300" }, " GN2Studio는 클라이언트의 요구를 충족시키기 위한 최상의 솔루션을 제공합니다. 웹사이트 개발, 인프라 구축, 그리고 AI 기반 솔루션 등 다양한 기술력을 바탕으로 고객 만족을 최우선으로 생각합니다. "),
              _createElementVNode("ul", { class: "space-y-4" }, [
                _createElementVNode("li", { class: "flex items-cneter" }, [
                  _createElementVNode("div", { class: "w-8 h-8 flex items-center justify-center text-white bg-blue-500 rounded-full" }, "01"),
                  _createElementVNode("p", { class: "ml-4 pt-1 text-gray-700 dark:text-gray-300" }, "고객 맞춤형 웹 및 모바일 솔루션 제공")
                ]),
                _createElementVNode("li", { class: "flex items-cneter" }, [
                  _createElementVNode("div", { class: "w-8 h-8 flex items-center justify-center text-white bg-blue-500 rounded-full" }, "02"),
                  _createElementVNode("p", { class: "ml-4 pt-1 text-gray-700 dark:text-gray-300" }, "AI 및 데이터 분석 서비스를 통한 비즈니스 향상")
                ]),
                _createElementVNode("li", { class: "flex items-cneter" }, [
                  _createElementVNode("div", { class: "w-8 h-8 flex items-center justify-center text-white bg-blue-500 rounded-full" }, "03"),
                  _createElementVNode("p", { class: "ml-4 pt-1 text-gray-700 dark:text-gray-300" }, "안정적인 클라우드 인프라 및 컨설팅")
                ])
              ])
            ], -1))
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})