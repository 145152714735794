<template>
<div class="container mx-auto h-screen flex items-center justify-center p-2">
  <div class="loginbox w-full max-w-4xl overflow-hidden flex justify-between">
    <div class="left-box box-content p-8 bg-orange-50 dark:bg-gray-950 rounded-lg shadow-sm w-2/5">
        <div class="w-full block dark:hidden">
          <router-link to="/" class="flex items-end space-x-2">
            <img src="/logo/muselogo.png" width="102" height="32" alt="MuseStudio" />
            <span class="playwrite-de-grund-guides-regular text-amber-700 text-xs mt-1">muse with gn2studio</span>
          </router-link>
        </div>
        <div class="w-full hidden dark:block">
          <router-link to="/" class="flex items-end space-x-2">
            <img src="/logo/muselogo.png" width="102" height="32" alt="MuseStudio" />
            <span class="playwrite-de-grund-guides-regular text-amber-700 text-xs mt-1">muse with gn2studio</span>
          </router-link>
        </div>
        <div class="text-orange-200 mt-3">
          <h3 class="text-2xl font-bold font-sans">{{ t('site.welcome') }}</h3>
          <p class="mt-1 text-gray-600 dark:text-gray-400">
            {{ t('site.welcome2') }}, {{ t('common.alreadyAccount') }}
            <router-link to="/login" class="text-orange-500 font-bold">{{ t('system.Login') }}</router-link>
          </p>
        </div>
        <form class="mt-6 space-y-4" @submit="fnSubmit">
          <div>
            <label class="block text-gray-700 dark:text-gray-300 font-semibold mb-2">Email</label>
            <GInput type="email" :placeholder="t('common.requiredEmail')" v-model="pageinfo.data.email" class="w-full p-2 border rounded" @keyup.enter="fnNext1" />
          </div>
          <div>
            <label class="block text-gray-700 dark:text-gray-300 font-semibold mb-2">Password</label>
            <GInput type="password" id="input_password" :placeholder="t('system.requiredPassword')" v-model="pageinfo.data.password" class="w-full p-2 border rounded" @keyup.enter="fnNext2" />
          </div>
          <div>
            <label class="block text-gray-700 dark:text-gray-300 font-semibold mb-2">Confirm Password</label>
            <GInput type="password" id="confirm_password" :placeholder="t('system.requiredPassword')" v-model="pageinfo.data.confirmpassword" class="w-full p-2 border rounded" @keyup.enter="fnSubmit" />
          </div>
          <div class="flex items-start">
            <input v-model="pageinfo.data.ischeck" type="checkbox" class="text-orange-500 focus:ring-2 focus:ring-orange-500 outline-none cursor-pointer mt-1" />
            <label class="ml-2 text-gray-700 dark:text-gray-300 cursor-pointer text-sm">
            {{ t('common.agree') }} <router-link class="text-orange-400" to="/document/term">{{ t('system.Terms') }}</router-link>, <router-link class="text-orange-400" to="/document/privacy">{{ t('system.Privacy') }}</router-link>.
            </label>
          </div>
          <div>
            <GButton size="md" @click="fnSubmit" class="w-full py-2 bg-orange-500 text-white font-bold rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500">{{ t('common.signup') }}</GButton>
          </div>
        </form>
      </div>
      <div class="right-box w-3/5 ml-2 hidden md:block">
        <img
          src="/banners/editor.jpg"
          alt="Banner"
          class="w-full h-auto rounded-md shadow-sm"
        />
    </div>
  </div>
</div>
</template>

<script setup lang="ts">
import { GInput, GButton } from '@/components/atoms';
import { AuthService } from '@/services';
import { MessageBox } from '@/utility';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';
import { HeadSet } from '@/utility';
import { useGlobalStore } from '@/store';
import router from '@/router';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const auth = new AuthService();
const route = useRoute();
const head = new HeadSet();
const site = useGlobalStore();
head.title = 'Regist - MuseStudio';

useHead(head.CreateJson());

var pageinfo = ref({
  key:0,
  isLoaded:false,
  data: {
    email:'',
    password:'',
    confirmpassword:'',
    ischeck:false
  },
  callbackUrl: '/'
});

onMounted(() => {
  if (route.query.backurl !== null && route.query.backurl !== undefined) {
    pageinfo.value.callbackUrl = String(route.query.backurl).trim() ?? '';
  }

  setTimeout(() => {
      site.setValue('loading', false);
  }, 100);
});

const fnNext1 = () => {
  document.getElementById('input_password')?.focus();
};

const fnNext2 = () => {
  document.getElementById('confirm_password')?.focus();
};

const fnSubmit = async () => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /\S{6,}/;

  if (pageinfo.value.data.email === "") {
    MessageBox.Alert(t('common.requiredEmail'));
  } else if (emailRegex.test(pageinfo.value.data.email) === false) {
    MessageBox.Alert(t('common.regexEmail'));
  } else if (pageinfo.value.data.password === "") {
    MessageBox.Alert(t('common.requiredPassword'));
  } else if (passwordRegex.test(pageinfo.value.data.password) === false) {
    MessageBox.Alert(t('common.regexPassword'));
  } else if (pageinfo.value.data.confirmpassword === "") {
    MessageBox.Alert(t('common.ConfirmPassword'));
  } else if (pageinfo.value.data.ischeck === false) {
    MessageBox.Alert(t('common.agreement'));
  } else {
    site.setValue('loading', true);
    let rst = await auth.apiRegist(pageinfo.value.data.email, pageinfo.value.data.password, pageinfo.value.data.confirmpassword);
    if (rst.check) {
      MessageBox.Success(t('common.registcomplete'), () => {
        router.push('/login');
      });
    } else {
      MessageBox.Alert(rst.message);
    }

    site.setValue('loading', false);
  }
};

</script>

<style scoped>
.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginbox {
  width:100%;
  max-width: 1000px;
  overflow:hidden;
  display:flex;
  justify-content: space-between;
}

.left-box { width:45%; }
.right-box { width:calc(55% - 8px);box-sizing: border-box;padding:0;margin-left:8px; }

@media (max-width: 768px) {
  .left-box { width:100%; }
  .right-box { display:none; }
}
</style>
