import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full bg-gray-200 dark:bg-gray-600 min-h-[400px] rounded-lg p-3" }
const _hoisted_2 = { class: "w-full p-3 bg-slate-100 dark:bg-slate-400 rounded-lg text-gray-700 font-semibold" }
const _hoisted_3 = { class: "mt-5 flex flex-col space-y-5 w-full text-gray-600 dark:text-gray-200" }

import { useI18n } from 'vue-i18n';
import { useMemberStore } from '@/store';
import { AuthService } from '@/services';
import { BoardContent, BoardRepository,Member } from '@gn2studio/gn2.library.common';
import config from '@/config';
import { onMounted, ref } from 'vue';
import { MessageBox } from '@/utility';
import { useRouter } from 'vue-router';

interface menuParameter {
    class?:string|null,
    position:number
}const activeClass = 'bg-slate-900 text-white rounded-md pl-4 pt-2 shadow-md';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerMenu',
  props: {
    class: {},
    position: {}
  },
  setup(__props: any) {

;

const router = useRouter();
const { t, locale } = useI18n();
const auth = new AuthService();
const memberStore = useMemberStore();
const property = __props;
var boardRep = new BoardRepository(config.apis.board, '', config.global.slug);
var componentInfo = ref({
    key:0,
    position:0,
    class:'',
    isLoggedIn:false,
    isLoaded:false,
    item:[] as BoardContent[],
    isPopup:false,
    accessToken:'',
    member:new Member(),
    data: {
        title : '',
        body : ''
    }
});

onMounted(async () => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.position = property.position;
    componentInfo.value.isLoaded = true;

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        boardRep = new BoardRepository(config.apis.board, token, config.global.slug);
        componentInfo.value.isLoggedIn = true;
    }
});


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, [
      _createTextVNode(_toDisplayString(_unref(t)('common.customer')) + " ", 1),
      _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fa-solid fa-house float-right mt-1" }, null, -1))
    ]),
    _createElementVNode("ul", _hoisted_3, [
      _createElementVNode("li", {
        class: _normalizeClass(["px-2 py-1", (_unref(componentInfo).position === 1) ? activeClass : ''])
      }, [
        _createVNode(_component_router_link, { to: "/customer/notice" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('common.notice')), 1)
          ]),
          _: 1
        })
      ], 2),
      _createElementVNode("li", {
        class: _normalizeClass(["px-2 py-1", (_unref(componentInfo).position === 2) ? activeClass : ''])
      }, [
        _createVNode(_component_router_link, { to: "/customer/faq" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('common.faq')), 1)
          ]),
          _: 1
        })
      ], 2),
      _createElementVNode("li", {
        class: _normalizeClass(["px-2 py-1", (_unref(componentInfo).position === 3) ? activeClass : ''])
      }, [
        _createVNode(_component_router_link, { to: "/request/form" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("제작문의")
          ])),
          _: 1
        })
      ], 2),
      _createElementVNode("li", {
        class: _normalizeClass(["px-2 py-1", (_unref(componentInfo).position === 4) ? activeClass : ''])
      }, [
        _createVNode(_component_router_link, { to: "/customer/bug" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('common.Bug')), 1)
          ]),
          _: 1
        })
      ], 2),
      _createElementVNode("li", {
        class: _normalizeClass(["px-2 py-1", (_unref(componentInfo).position === 5) ? activeClass : ''])
      }, [
        _createVNode(_component_router_link, { to: "/customer/partner" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('common.Partnership')), 1)
          ]),
          _: 1
        })
      ], 2)
    ])
  ]))
}
}

})