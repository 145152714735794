import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, ref,watch } from 'vue';

interface buttonParameter {
    class?:string|null,
    size?:string|null
}
export default /*@__PURE__*/_defineComponent({
  __name: 'GButton',
  props: {
    class: {},
    size: {}
  },
  setup(__props: any) {

;

const property = __props;

var componentInfo = ref({
    key:0,
    isLoaded:false,
    class:'',
    size:''
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.size = property.size ?? 'md';

    switch (componentInfo.value.size) {
        case "xs":
            componentInfo.value.class += ` text-xs`
            break;
        case "sm":
            componentInfo.value.class += ` text-sm`
            break;
        case "md":
            componentInfo.value.class += ` text-md`
            break;
        case "lg":
            componentInfo.value.class += ` text-lg`
            break;
        case "xl":
            componentInfo.value.class += ` text-xl`
            break;
        case "2xl":
            componentInfo.value.class += ` text-2xl`
            break;
    }

    componentInfo.value.isLoaded = true;
});

watch(() => property,
    (newValue) => {
        componentInfo.value.size = (newValue.size !== null && newValue.size !== undefined) ? newValue.size : '';
        componentInfo.value.class = (newValue.class !== null && newValue.class !== undefined) ? newValue.class : '';
        componentInfo.value.key += 1;
    }
);


return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createElementBlock("button", {
        type: "button",
        class: _normalizeClass(_unref(componentInfo).class),
        key: `button_${_unref(componentInfo).key}`
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2))
    : _createCommentVNode("", true)
}
}

})