import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { GContainer } from '@/components';
import { useGlobalStore } from '@/store';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'Service',
  setup(__props) {

const site = useGlobalStore();
const { t, locale } = useI18n();

onMounted(async () => {
    setTimeout(() => {
        site.setValue('loading', false);
    }, 100);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(GContainer), null, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("section", {
        id: "services",
        class: "bg-gray-50 dark:bg-gray-800 py-12 rounded-lg"
      }, [
        _createElementVNode("div", { class: "container mx-auto px-6 text-gray-900 dark:text-gray-100" }, [
          _createElementVNode("div", { class: "text-center mb-12" }, [
            _createElementVNode("h2", { class: "text-3xl font-bold" }, "우리의 서비스"),
            _createElementVNode("p", { class: "mt-4 text-gray-600 dark:text-gray-300" }, "GN2Studio가 제공하는 다양한 서비스를 확인해보세요.")
          ]),
          _createElementVNode("div", { class: "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6" }, [
            _createElementVNode("div", { class: "flex flex-col items-center p-6 bg-white dark:bg-gray-700 rounded-lg shadow-md" }, [
              _createElementVNode("img", {
                src: "/logo/Logo_600x600.jpg",
                class: "w-full h-48 object-cover rounded-md mb-4",
                alt: "Muse Studio"
              }),
              _createElementVNode("h3", { class: "text-lg font-bold" }, "AI집필보조 서비스"),
              _createElementVNode("p", { class: "mt-2 text-gray-600 dark:text-gray-300" }, [
                _createTextVNode(" MuseStudio는 웹소설 작가들을 위한 혁신적인 창작 플랫폼입니다."),
                _createElementVNode("br"),
                _createTextVNode(" 글 작성 시 필요한 자료를 손쉽게 확인하고, AI의 도움을 받아 초안 작성과 의견을 받을 수 있습니다. ")
              ])
            ]),
            _createElementVNode("div", { class: "flex flex-col items-center p-6 bg-white dark:bg-gray-700 rounded-lg shadow-md" }, [
              _createElementVNode("img", {
                src: "/logo/logo_box_bg.png",
                class: "w-full h-48 object-cover rounded-md mb-4",
                alt: "MerryTokTok"
              }),
              _createElementVNode("h3", { class: "text-lg font-bold" }, "이슈기반 커뮤니티 서비스"),
              _createElementVNode("p", { class: "mt-2 text-gray-600 dark:text-gray-300" }, " 재미난 이야기, 웹소설 리뷰, 영화 리뷰, 인플루언서, 셀럽, 뉴스 등 다양한 이야기를 공유합니다. ")
            ]),
            _createElementVNode("div", { class: "flex flex-col items-center p-6 bg-white dark:bg-gray-700 rounded-lg shadow-md" }, [
              _createElementVNode("img", {
                src: "https://via.placeholder.com/350x200",
                class: "w-full h-48 object-cover rounded-md mb-4",
                alt: ""
              }),
              _createElementVNode("h3", { class: "text-lg font-bold" }, "준비중입니다."),
              _createElementVNode("p", { class: "mt-2 text-gray-600 dark:text-gray-300" }, " 끊임없이 새로운 서비스를 준비하고 있습니다. ")
            ])
          ]),
          _createElementVNode("div", { class: "mt-12 flex justify-center" }, [
            _createElementVNode("nav", { class: "flex items-center space-x-4" }, [
              _createElementVNode("button", { class: "px-4 py-2 bg-gray-300 dark:bg-gray-600 text-gray-900 dark:text-gray-100 rounded-md shadow-md hover:bg-gray-400 dark:hover:bg-gray-500" }, " 이전 "),
              _createElementVNode("span", { class: "text-gray-700 dark:text-gray-300" }, "1 / 1"),
              _createElementVNode("button", { class: "px-4 py-2 bg-gray-300 dark:bg-gray-600 text-gray-900 dark:text-gray-100 rounded-md shadow-md hover:bg-gray-400 dark:hover:bg-gray-500" }, " 다음 ")
            ])
          ])
        ])
      ], -1)
    ])),
    _: 1
  }))
}
}

})