<template>
    <GContainer>
    <!-- About Us Section -->
    <section id="about-us" class="bg-gray-50 dark:bg-gray-800 py-12 rounded-lg">
      <div class="container mx-auto px-6 text-gray-900 dark:text-gray-100">
        <!-- Header -->
        <div class="text-center mb-12">
          <h1 class="text-4xl font-bold">회사소개</h1>
          <p class="mt-4 text-gray-600 dark:text-gray-300">GN2Studio는 최고의 기술력과 서비스를 제공하기 위해 노력합니다.</p>
        </div>
    
        <!-- Company Overview -->
        <div class="flex flex-col lg:flex-row items-center lg:items-start space-y-12 lg:space-y-0 lg:space-x-12">
          <!-- Image -->
          <div class="lg:w-1/2">
            <GImage src="/images/bg/cardBg.jpg" class="w-full h-80 rounded-lg shadow-lg" alt="" />
          </div>
    
          <!-- Content -->
          <div class="lg:w-1/2 space-y-6">
            <h2 class="text-3xl font-bold">우리의 목표</h2>
            <p class="text-gray-700 dark:text-gray-300">
              GN2Studio는 클라이언트의 요구를 충족시키기 위한 최상의 솔루션을 제공합니다. 웹사이트 개발, 인프라 구축, 그리고 AI 기반 솔루션 등 다양한 기술력을 바탕으로 고객 만족을 최우선으로 생각합니다.
            </p>
            <ul class="space-y-4">
              <li class="flex items-cneter">
                <div class="w-8 h-8 flex items-center justify-center text-white bg-blue-500 rounded-full">01</div>
                <p class="ml-4 pt-1 text-gray-700 dark:text-gray-300">고객 맞춤형 웹 및 모바일 솔루션 제공</p>
              </li>
              <li class="flex items-cneter">
                <div class="w-8 h-8 flex items-center justify-center text-white bg-blue-500 rounded-full">02</div>
                <p class="ml-4 pt-1 text-gray-700 dark:text-gray-300">AI 및 데이터 분석 서비스를 통한 비즈니스 향상</p>
              </li>
              <li class="flex items-cneter">
                <div class="w-8 h-8 flex items-center justify-center text-white bg-blue-500 rounded-full">03</div>
                <p class="ml-4 pt-1 text-gray-700 dark:text-gray-300">안정적인 클라우드 인프라 및 컨설팅</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    
    <!--
    <section id="team" class="py-12">
      <div class="container mx-auto text-gray-900 dark:text-gray-100">
        <div class="text-center mb-12">
          <h2 class="text-3xl font-bold">우리 팀</h2>
          <p class="mt-4 text-gray-600 dark:text-gray-300">다양한 전문성을 가진 팀이 여러분과 함께합니다.</p>
        </div>
    
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div class="flex flex-col items-center p-6 bg-white dark:bg-gray-700 rounded-lg shadow-md">
            <img src="/images/team/member1.jpg" class="w-24 h-24 rounded-full mb-4" alt="Team Member">
            <h3 class="text-lg font-bold">김철수</h3>
            <p class="text-gray-600 dark:text-gray-300">CEO</p>
          </div>
          <div class="flex flex-col items-center p-6 bg-white dark:bg-gray-700 rounded-lg shadow-md">
            <img src="/images/team/member2.jpg" class="w-24 h-24 rounded-full mb-4" alt="Team Member">
            <h3 class="text-lg font-bold">이영희</h3>
            <p class="text-gray-600 dark:text-gray-300">CTO</p>
          </div>
          <div class="flex flex-col items-center p-6 bg-white dark:bg-gray-700 rounded-lg shadow-md">
            <img src="/images/team/member3.jpg" class="w-24 h-24 rounded-full mb-4" alt="Team Member">
            <h3 class="text-lg font-bold">박민수</h3>
            <p class="text-gray-600 dark:text-gray-300">Lead Developer</p>
          </div>
        </div>
      </div>
    </section>
    -->
</GContainer>
    </template>

<script setup lang="ts">
import { GContainer, GImage } from '@/components';
import { useGlobalStore } from '@/store';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const site = useGlobalStore();
const { t, locale } = useI18n();

onMounted(async () => {
    setTimeout(() => {
        site.setValue('loading', false);
    }, 100);
});

</script>