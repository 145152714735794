export class HeadSet {
    public title:string;
    public keywords:string;
    public url:string;
    public image:string;
    public description:string;
    public author:string;

    constructor() {
        this.title = 'MUSE - 웹소설 작가를 위한 AI 기반 창작 플랫폼';
        this.keywords = '웹소설 작가, 웹소설 창작, AI 글쓰기 도구, 창작 플랫폼, 시놉시스 작성, 글쓰기 목표 설정, GN2Studio, 웹소설 초안 작성, AI 기반 창작 도구';
        this.url = "https://muse.gn2studio.com";
        this.image = "/logo/Logo_600x600.jpg";
        this.description = 'MuseStudio는 웹소설 작가들을 위한 혁신적인 창작 플랫폼입니다. 글 작성 시 필요한 자료를 손쉽게 확인하고, AI의 도움을 받아 초안 작성과 의견을 받을 수 있습니다. 목표량 설정 및 시놉시스 작성 도구를 통해 체계적으로 웹소설을 완성해 보세요.';
        this.author = "https://muse.gn2studio.com";
    }

    CreateJson() {
        return {
            title: this.title,
            meta: [
                { name: 'keywords', content: this.keywords },
                { name: 'description', content: this.description },
                { name: 'author', content: this.url },
                { property: 'og:type', content: 'website' },
                { property: 'og:title', content: this.title },
                { property: 'og:description', content: this.description },
                { property: 'og:image', content: this.image },
                { property: 'og:url', content: this.url },
                { name: 'twitter:card', content: 'summary' },
                { name: 'twitter:title', content: this.title },
                { name: 'twitter:description', content: this.description },
                { name: 'twitter:image', content: this.image },
                { name: 'twitter:domain', content: this.url },
            ]
        }
    }
}