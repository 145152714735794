import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex justify-start p-6 lg:p-1 lg:space-x-8 pt-3" }
const _hoisted_2 = { class: "hidden lg:block w-1/6 mt-5" }
const _hoisted_3 = { class: "w-full lg:w-5/6 space-y-10" }
const _hoisted_4 = { class: "text-center space-y-4" }
const _hoisted_5 = { class: "mt-8 text-4xl font-extrabold text-gray-900 dark:text-white" }
const _hoisted_6 = { class: "text-gray-600 dark:text-gray-200 text-lg" }
const _hoisted_7 = { class: "flex flex-col lg:flex-row items-start gap-8" }
const _hoisted_8 = { class: "text-gray-700 dark:text-gray-200 text-base leading-relaxed box hidden lg:block pt-2" }
const _hoisted_9 = { class: "mt-4" }
const _hoisted_10 = { class: "text-gray-700 dark:text-gray-200 text-base leading-relaxed block lg:hidden" }
const _hoisted_11 = { class: "mt-4" }
const _hoisted_12 = { class: "space-y-6" }
const _hoisted_13 = { class: "bg-blue-50 dark:bg-blue-950 rounded-lg p-6 text-center" }
const _hoisted_14 = { class: "text-2xl font-semibold text-blue-700 dark:text-gray-100" }
const _hoisted_15 = { class: "text-blue-600 dark:text-gray-100 mt-2" }

import { GContainer, CustomerMenu, GAccordion,GImage } from '@/components';
import config from '@/config';
import { BoardContent, BoardRepository,PagingParameter } from '@gn2studio/gn2.library.common';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useGlobalStore } from '@/store';
import router from '@/router';


export default /*@__PURE__*/_defineComponent({
  __name: 'FAQ',
  setup(__props) {

const { t, locale } = useI18n();
const site = useGlobalStore();

var pageInfo = ref({
    key:0,
    isLoaded:false,
    totalCount:0,
    paging: new PagingParameter(),
    items:[] as BoardContent[]
});
var boardRep = new BoardRepository(config.apis.board, '', config.global.slug);

onMounted(async () => {
    await getFAQ();
    pageInfo.value.isLoaded = true;

    setTimeout(() => {
        site.setValue('loading', false);
    }, 100);
});

const getFAQ = async () => {
    let rst = await boardRep.GetBoards('faq', pageInfo.value.paging);
    if (rst !== null && rst !== undefined && rst.check) {
        pageInfo.value.totalCount = rst.code;
        pageInfo.value.items = rst.data;
    }
};

const fnMoveQna = () => {
    router.push('/customer/qna/edit');
};


return (_ctx: any,_cache: any) => {
  return (_unref(pageInfo).isLoaded)
    ? (_openBlock(), _createBlock(_unref(GContainer), {
        class: "",
        key: _unref(pageInfo).key
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(CustomerMenu), { position: 2 })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("h1", _hoisted_5, _toDisplayString(_unref(t)('common.faq')), 1),
                _createElementVNode("p", _hoisted_6, [
                  _createTextVNode(_toDisplayString(_unref(t)('common.faqment1')) + " ", 1),
                  _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                  _createElementVNode("a", {
                    onClick: fnMoveQna,
                    class: "text-blue-500 hover:underline font-medium cursor-pointer"
                  }, _toDisplayString(_unref(t)('common.faqment2')), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_unref(GImage), {
                  src: "/images/faq.png",
                  width: "400px",
                  height: "250px",
                  class: "hidden lg:block rounded-lg shadow-md"
                }),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("p", null, [
                    _createTextVNode(_toDisplayString(_unref(t)('common.faqment3')), 1),
                    _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_unref(t)('common.faqment4')), 1)
                  ]),
                  _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(t)('common.faqment5')), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("p", null, [
                    _createTextVNode(_toDisplayString(_unref(t)('common.faqment3')), 1),
                    _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_unref(t)('common.faqment4')), 1)
                  ]),
                  _createElementVNode("p", _hoisted_11, _toDisplayString(_unref(t)('common.faqment5')), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pageInfo).items, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `Accordion_${index}`
                  }, [
                    _createVNode(_unref(GAccordion), {
                      title: item.title,
                      body: item.content
                    }, null, 8, ["title", "body"])
                  ]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("h2", _hoisted_14, _toDisplayString(_unref(t)('common.faqment6')), 1),
                _createElementVNode("p", _hoisted_15, _toDisplayString(_unref(t)('common.faqment7')), 1),
                _createElementVNode("button", {
                  class: "mt-4 px-6 py-3 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition",
                  onClick: fnMoveQna
                }, _toDisplayString(_unref(t)('common.customer')) + " " + _toDisplayString(_unref(t)('common.question')), 1)
              ])
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})