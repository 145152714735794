<template>
    <GContainer>
<!-- Timeline Section -->
<section id="timeline" class="bg-gray-50 dark:bg-gray-800 p-12 rounded-lg">
    <div class="container mx-auto px-6 text-gray-900 dark:text-gray-100">
    <!-- Header -->
    <div class="text-center mb-12">
        <h2 class="text-3xl font-bold">업력</h2>
        <p class="mt-4 text-gray-600 dark:text-gray-300">GN2Studio의 주요 이정표를 확인해보세요.</p>
    </div>

    <!-- Timeline -->
    <div class="relative border-l-4 border-blue-500 lg:ml-16">
        <!-- Milestone 1 -->
        <div class="mb-8 ml-8">
        <div class="absolute w-6 h-6 bg-blue-500 rounded-full -left-3.5"></div>
        <h3 class="text-lg font-bold">2025년</h3>
        <p class="mt-2 text-gray-700 dark:text-gray-300">삼성전자 반도체사업부 인증솔루션 구축 참여</p>
        </div>

        <!-- Milestone 2 -->
        <div class="mb-8 ml-8">
        <div class="absolute w-6 h-6 bg-blue-500 rounded-full -left-3.5"></div>
        <h3 class="text-lg font-bold">2024년 8월</h3>
        <p class="mt-2 text-gray-700 dark:text-gray-300">사업자신고, GN2Studio 사업개시</p>
        </div>

    </div>
    </div>
</section>


</GContainer>
</template>

<script setup lang="ts">
import { GContainer } from '@/components';
import { useGlobalStore } from '@/store';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const site = useGlobalStore();
const { t, locale } = useI18n();

onMounted(async () => {
    setTimeout(() => {
        site.setValue('loading', false);
    }, 100);
});
</script>