import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative container mx-auto p-6" }
const _hoisted_2 = { class: "flex item-center justify-between" }
const _hoisted_3 = { class: "flex space-x-6 text-gray-900 dark:text-gray-100 font-bold" }

import { useI18n } from 'vue-i18n';
  import { GContainer,SearchField,ProfileButton,LogoutButton,RegistButton,LoginButton } from '@/components';
  import { useRouter } from 'vue-router';
  import { onMounted, ref, onUnmounted,watch } from 'vue';
  import { AuthService } from '@/services';
  import { useMemberStore } from '@/store';
  import { Member, MemberRepository, ValidateHelper } from '@gn2studio/gn2.library.common';
  import config from '@/config';
  import { MessageBox } from '@/utility';
  import DefaultButton from '../molecules/DefaultButton.vue';
  
  interface headerParameter {
    menu?:number|null|undefined
  }
export default /*@__PURE__*/_defineComponent({
  __name: 'Header',
  props: {
    menu: {}
  },
  setup(__props: any) {

  const { t, locale } = useI18n();
  const router = useRouter();
  const auth = new AuthService();
  const memberStore = useMemberStore();
  const currentPath = ref(location.pathname);
  const validate = new ValidateHelper();

  ;

  const property = __props;

  var componentInfo = ref({
    key:0,
    menutab:0,
    member:null as Member|null,
    isShowSide:false,
    isLogin:false
  });

  onMounted(async () => {
    componentInfo.value.menutab = property.menu ?? 0;

    const accessToken = await auth.getAccessToken();

    if (accessToken !== null && accessToken !== undefined && String(accessToken).trim() !== '') {
      componentInfo.value.member = memberStore.member
      if (componentInfo.value.member === null 
      || componentInfo.value.member === undefined 
      || componentInfo.value.member.id === null 
      || componentInfo.value.member.id === undefined 
      || String(componentInfo.value.member.id).trim() === '')
      {
        const memberRep = new MemberRepository(config.apis.member, accessToken);
        const rst = await memberRep.GetUser();
        if (rst.check) {
          componentInfo.value.member = rst.data;
          
        } else {
          await auth.refreshSilent();
        }
        memberStore.setMember(rst.data);
      }

      componentInfo.value.isLogin = true;
      componentInfo.value.key += 1;
    }

    urlChange(location.pathname);

    window.addEventListener('popstate', () => {
      currentPath.value = location.pathname;
    });
  });

  onUnmounted(() => {
    window.removeEventListener('popstate', () => {
      currentPath.value = location.pathname;
    });
  });

  watch([currentPath, () => memberStore.member],
    ([newPath, newMember], [oldPath, oldMember]) => {
      if (newPath !== oldPath) {
        urlChange(newPath);
      }

      if (newMember !== oldMember) {
        componentInfo.value.member = memberStore.member;
        componentInfo.value.key += 1;
      }
    }
  );

  const fnLogin = () => {
    router.push(`/login?backurl=` + location.pathname);
  };
  
  const fnRegist = () => {
    router.push(`/regist`);
  };

  const fnLogout = () => {
    MessageBox.Confirm(t('system.logout_ment'), async () => {
      await auth.logout();
      memberStore.clearMember();
    });
  };

  const urlChange = (url:string) => {
    if (false === validate.IsNullOrWhiteSpace(url)) {
      if (url.indexOf('/') > -1) {
        if (url.split('/')[0].trim() === '') {
          url = url.split('/')[1].trim().toLowerCase();
        } else {
          url = url.split('/')[0].trim().toLowerCase();
        }
      }
    }

    switch (url) {
      case "content":
        componentInfo.value.menutab = 1;
        componentInfo.value.key += 1;
        break;
      case "album":
        componentInfo.value.menutab = 2;
        componentInfo.value.key += 1;
        break;
      case "customer":
        componentInfo.value.menutab = 3;
        componentInfo.value.key += 1;
        break;
    }
  };

  const fnSideMenu = () => {
    componentInfo.value.isShowSide = !componentInfo.value.isShowSide;
  };

  const fnProfile = () => {
    router.push(`/profile`);
  };

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(GContainer), null, {
    default: _withCtx(() => [
      (_openBlock(), _createElementBlock("header", {
        key: `Header_${_unref(componentInfo).key}`
      }, [
        _createElementVNode("nav", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_link, { to: "/" }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("img", {
                  src: "/logo/Logo_Blue.png",
                  alt: "logo image",
                  class: "w-[146px] h-auto"
                }, null, -1)
              ])),
              _: 1
            }),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_router_link, {
                to: "/Intro",
                class: ""
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("회사소개")
                ])),
                _: 1
              }),
              _createVNode(_component_router_link, {
                to: "/History",
                class: ""
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("업력")
                ])),
                _: 1
              }),
              _createVNode(_component_router_link, {
                to: "/Service",
                class: ""
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("서비스")
                ])),
                _: 1
              }),
              _createVNode(_component_router_link, {
                to: "/customer",
                class: ""
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("고객센터")
                ])),
                _: 1
              })
            ])
          ])
        ])
      ]))
    ]),
    _: 1
  }))
}
}

})