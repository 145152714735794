<template>
  <GContainer class="" v-if="pageInfo.isLoaded" :key="pageInfo.key">
    <div class="w-full flex justify-start p-6 lg:p-1 lg:space-x-8 pt-3">
        <div class="hidden lg:block w-1/6 mt-5">
            <CustomerMenu :position="2"></CustomerMenu>
        </div>
        <div class="w-full lg:w-5/6 space-y-10">
            <!-- 상단 FAQ 소개 -->
            <div class="text-center space-y-4">
            <h1 class="mt-8 text-4xl font-extrabold text-gray-900 dark:text-white">{{ t('common.faq') }}</h1>
            <p class="text-gray-600 dark:text-gray-200 text-lg">
                {{ t('common.faqment1') }} <br />
                <a @click="fnMoveQna" class="text-blue-500 hover:underline font-medium cursor-pointer">{{ t('common.faqment2') }}</a>
            </p>
            </div>

            <!-- FAQ 이미지 및 소개 -->
            <div class="flex flex-col lg:flex-row items-start gap-8">
                <GImage src="/images/faq.png" width="400px" height="250px" class="hidden lg:block rounded-lg shadow-md"></GImage>
                <div class="text-gray-700 dark:text-gray-200 text-base leading-relaxed box hidden lg:block pt-2">
                    <p>
                        {{ t('common.faqment3') }}<br/> 
                        {{ t('common.faqment4') }}
                    </p>
                    <p class="mt-4">
                        {{ t('common.faqment5') }}
                    </p>
                </div>
                <div class="text-gray-700 dark:text-gray-200 text-base leading-relaxed block lg:hidden">
                    <p>
                        {{ t('common.faqment3') }}<br/> 
                        {{ t('common.faqment4') }}
                    </p>
                    <p class="mt-4">
                        {{ t('common.faqment5') }}
                    </p>
                </div>
            </div>

            <!-- FAQ 리스트 -->
            <div class="space-y-6">
                <div v-for="(item,index) in pageInfo.items" :key="`Accordion_${index}`">
                    <GAccordion :title="item.title" :body="item.content" />
                </div>
            </div>

            <!-- FAQ 하단 -->
            <div class="bg-blue-50 dark:bg-blue-950 rounded-lg p-6 text-center">
            <h2 class="text-2xl font-semibold text-blue-700 dark:text-gray-100">{{ t('common.faqment6') }}</h2>
            <p class="text-blue-600 dark:text-gray-100 mt-2">
                {{ t('common.faqment7') }}
            </p>
            <button class="mt-4 px-6 py-3 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition" @click="fnMoveQna">
                {{ t('common.customer') }} {{ t('common.question') }}
            </button>
            </div>
        </div>
    </div>
  </GContainer>
</template>


<script setup lang="ts">
import { GContainer, CustomerMenu, GAccordion,GImage } from '@/components';
import config from '@/config';
import { BoardContent, BoardRepository,PagingParameter } from '@gn2studio/gn2.library.common';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useGlobalStore } from '@/store';
import router from '@/router';

const { t, locale } = useI18n();
const site = useGlobalStore();

var pageInfo = ref({
    key:0,
    isLoaded:false,
    totalCount:0,
    paging: new PagingParameter(),
    items:[] as BoardContent[]
});
var boardRep = new BoardRepository(config.apis.board, '', config.global.slug);

onMounted(async () => {
    await getFAQ();
    pageInfo.value.isLoaded = true;

    setTimeout(() => {
        site.setValue('loading', false);
    }, 100);
});

const getFAQ = async () => {
    let rst = await boardRep.GetBoards('faq', pageInfo.value.paging);
    if (rst !== null && rst !== undefined && rst.check) {
        pageInfo.value.totalCount = rst.code;
        pageInfo.value.items = rst.data;
    }
};

const fnMoveQna = () => {
    router.push('/customer/qna/edit');
};

</script>

<style scoped>
.box { width:calc(100% - 400px); }
</style>