import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, ref, watch } from 'vue';
    import { useRouter } from 'vue-router';
    import { StringHelper } from '@gn2studio/gn2.library.common';
    
    interface imgParameter {
        src:string,
        width?:string|null|undefined,
        height?:string|null|undefined,
        border?:number|null|undefined,
        link?:string|null|undefined,
        class?:string|null|undefined
    }

    
export default /*@__PURE__*/_defineComponent({
  __name: 'GImage',
  props: {
    src: {},
    width: {},
    height: {},
    border: {},
    link: {},
    class: {}
  },
  setup(__props: any) {

    const property = __props;
    const router = useRouter();

    var componentInfo = ref({
        src:"",
        link:"",
        class:"",
        isLoaded:false,
        key:0
    });

    onMounted(() => {
        componentInfo.value.src = property.src;
        if (property.src === null || property.src === undefined || property.src.trim() === "") {
            let path = 'https://via.placeholder.com/';
            if (property.width !== null && property.width !== undefined && String(property.width).trim() !== '') {
                if (StringHelper.extractNumbersFromString(property.width) === '') {
                    path += `100`;
                } else {
                    path += `${StringHelper.extractNumbersFromString(property.width)}`;
                }
                
            }
            if (property.height !== null && property.height !== undefined && String(property.height).trim() !== '') {
                if (StringHelper.extractNumbersFromString(property.height) === '') {
                    path += `x100`;
                } else {
                    path += `x${StringHelper.extractNumbersFromString(property.height)}`;
                }
                
            }
            componentInfo.value.src = path;
        }

        componentInfo.value.link = property.link ?? "";
        componentInfo.value.class = property.class ?? "";

        componentInfo.value.isLoaded = true;
    });

    watch(() => property,
        (newValue) => {
            componentInfo.value.src = (newValue.src !== null && newValue.src !== undefined) ? newValue.src : '';
            componentInfo.value.class = (newValue.class !== null && newValue.class !== undefined) ? newValue.class : '';
            componentInfo.value.key += 1;
        }
    );

    const fnClick = () => { 
        if (componentInfo.value.link !== null && componentInfo.value.link !== undefined && String(componentInfo.value.link).trim() !== '') {
            router.push(componentInfo.value.link);
        }
    };

    const fnStyle = () => {
        let result = '';

        if (property.width !== null && property.width !== undefined && String(property.width).trim() !== '') {
            result += `width:${property.width};`;
        }

        if (property.height !== null && property.height !== undefined && String(property.height).trim() !== '') {
            result += `height:${property.height};`;
        }

        if (componentInfo.value.src !== null && componentInfo.value.src !== undefined && String(componentInfo.value.src).trim() !== '') {
            result += `background-image:url('${componentInfo.value.src}');`;
        }

        if (property.border !== null && property.border !== undefined && String(property.border).trim() !== '') {
            result += `border-width:${property.border}px;`;
        }

        return result;
    };

return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["imageCover border-gray-400 dark:border-gray-800", _unref(componentInfo).class]),
        style: _normalizeStyle(fnStyle()),
        onClick: fnClick
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 6))
    : _createCommentVNode("", true)
}
}

})