import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { GContainer } from '@/components';
import { useGlobalStore } from '@/store';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'history',
  setup(__props) {

const site = useGlobalStore();
const { t, locale } = useI18n();

onMounted(async () => {
    setTimeout(() => {
        site.setValue('loading', false);
    }, 100);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(GContainer), null, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("section", {
        id: "timeline",
        class: "bg-gray-50 dark:bg-gray-800 p-12 rounded-lg"
      }, [
        _createElementVNode("div", { class: "container mx-auto px-6 text-gray-900 dark:text-gray-100" }, [
          _createElementVNode("div", { class: "text-center mb-12" }, [
            _createElementVNode("h2", { class: "text-3xl font-bold" }, "업력"),
            _createElementVNode("p", { class: "mt-4 text-gray-600 dark:text-gray-300" }, "GN2Studio의 주요 이정표를 확인해보세요.")
          ]),
          _createElementVNode("div", { class: "relative border-l-4 border-blue-500 lg:ml-16" }, [
            _createElementVNode("div", { class: "mb-8 ml-8" }, [
              _createElementVNode("div", { class: "absolute w-6 h-6 bg-blue-500 rounded-full -left-3.5" }),
              _createElementVNode("h3", { class: "text-lg font-bold" }, "2025년"),
              _createElementVNode("p", { class: "mt-2 text-gray-700 dark:text-gray-300" }, "삼성전자 반도체사업부 인증솔루션 구축 참여")
            ]),
            _createElementVNode("div", { class: "mb-8 ml-8" }, [
              _createElementVNode("div", { class: "absolute w-6 h-6 bg-blue-500 rounded-full -left-3.5" }),
              _createElementVNode("h3", { class: "text-lg font-bold" }, "2024년 8월"),
              _createElementVNode("p", { class: "mt-2 text-gray-700 dark:text-gray-300" }, "사업자신고, GN2Studio 사업개시")
            ])
          ])
        ])
      ], -1)
    ])),
    _: 1
  }))
}
}

})