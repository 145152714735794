<template>
<GContainer>
    <section id="services" class="bg-gray-50 dark:bg-gray-800 py-12 rounded-lg">
  <div class="container mx-auto px-6 text-gray-900 dark:text-gray-100 ">
    <!-- Header -->
    <div class="text-center mb-12">
      <h2 class="text-3xl font-bold">우리의 서비스</h2>
      <p class="mt-4 text-gray-600 dark:text-gray-300">GN2Studio가 제공하는 다양한 서비스를 확인해보세요.</p>
    </div>

    <!-- Services Grid -->
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      <!-- Service Card 1 -->
      <div class="flex flex-col items-center p-6 bg-white dark:bg-gray-700 rounded-lg shadow-md">
        <img src="/logo/Logo_600x600.jpg" class="w-full h-48 object-cover rounded-md mb-4" alt="Muse Studio">
        <h3 class="text-lg font-bold">AI집필보조 서비스</h3>
        <p class="mt-2 text-gray-600 dark:text-gray-300">
          MuseStudio는 웹소설 작가들을 위한 혁신적인 창작 플랫폼입니다.<br/>
          글 작성 시 필요한 자료를 손쉽게 확인하고, AI의 도움을 받아 초안 작성과 의견을 받을 수 있습니다. 
        </p>
      </div>

      <!-- Service Card 2 -->
      <div class="flex flex-col items-center p-6 bg-white dark:bg-gray-700 rounded-lg shadow-md">
        <img src="/logo/logo_box_bg.png" class="w-full h-48 object-cover rounded-md mb-4" alt="MerryTokTok">
        <h3 class="text-lg font-bold">이슈기반 커뮤니티 서비스</h3>
        <p class="mt-2 text-gray-600 dark:text-gray-300">
          재미난 이야기, 웹소설 리뷰, 영화 리뷰, 인플루언서, 셀럽, 뉴스 등 다양한 이야기를 공유합니다.
        </p>
      </div>

      <!-- Placeholder for more services -->
      <div class="flex flex-col items-center p-6 bg-white dark:bg-gray-700 rounded-lg shadow-md">
        <img src="https://via.placeholder.com/350x200" class="w-full h-48 object-cover rounded-md mb-4" alt="">
        <h3 class="text-lg font-bold">준비중입니다.</h3>
        <p class="mt-2 text-gray-600 dark:text-gray-300">
          끊임없이 새로운 서비스를 준비하고 있습니다.
        </p>
      </div>
    </div>

    <!-- Pagination -->
    <div class="mt-12 flex justify-center">
      <nav class="flex items-center space-x-4">
        <button class="px-4 py-2 bg-gray-300 dark:bg-gray-600 text-gray-900 dark:text-gray-100 rounded-md shadow-md hover:bg-gray-400 dark:hover:bg-gray-500">
          이전
        </button>
        <span class="text-gray-700 dark:text-gray-300">1 / 1</span>
        <button class="px-4 py-2 bg-gray-300 dark:bg-gray-600 text-gray-900 dark:text-gray-100 rounded-md shadow-md hover:bg-gray-400 dark:hover:bg-gray-500">
          다음
        </button>
      </nav>
    </div>
  </div>
</section>



</GContainer>
</template>

<script setup lang="ts">
import { GContainer } from '@/components';
import { useGlobalStore } from '@/store';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const site = useGlobalStore();
const { t, locale } = useI18n();

onMounted(async () => {
    setTimeout(() => {
        site.setValue('loading', false);
    }, 100);
});
</script>