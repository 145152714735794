<template>
  <button 
    type="button" 
    :disabled="isLoading" 
    v-if="componentInfo.isLoaded" 
    :key="componentInfo.key"
    class="absolute top-0 right-0 mt-2 mr-3 flex flex-shrink-0 focus:outline-none text-blue-600 hover:text-blue-700 w-6 h-6"
  >
    <!-- 로딩 상태가 아닐 때 스마일 아이콘 -->
    <svg 
      v-if="!isLoading"
      viewBox="0 0 20 20" 
      class="w-full h-full fill-current"
    >
      <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM6.5 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm7 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm2.16 3a6 6 0 0 1-11.32 0h11.32z" />
    </svg>

    <!-- 로딩 상태일 때 회전하는 원형 아이콘 -->
    <svg 
      v-if="isLoading"
      class="w-full h-full fill-current animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
    <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" stop-color="#3B82F6" />
      <stop offset="50%" stop-color="#06B6D4" />
      <stop offset="100%" stop-color="#3B82F6" />
    </linearGradient>
  </defs>
  <circle
    cx="12"
    cy="12"
    r="10"
    stroke="url(#gradient)"
    stroke-width="4"
    fill="none"
    stroke-dasharray="62.8319"
    stroke-dashoffset="0"
  >

  </circle>
    </svg>
  </button>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';

// prop 정의
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  }
});

// 이벤트 정의
const emit = defineEmits(['update:modelValue']);

const isLoading = ref(props.modelValue);
const componentInfo = ref({
  class: '',
  key: 0,
  isLoaded: false,
  isLoading: false
});

onMounted(() => {
  componentInfo.value.isLoaded = true;
});

// 컴포넌트 로딩 상태 업데이트
watch(() => props.modelValue, (newVal) => {
  isLoading.value = newVal;
});
</script>

<style scoped>
/* 회전 애니메이션 */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}
</style>
