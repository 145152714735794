<template>
<div class="w-full bg-gray-200 dark:bg-gray-600 min-h-[400px] rounded-lg p-3">
    <h3 class="w-full p-3 bg-slate-100 dark:bg-slate-400 rounded-lg text-gray-700 font-semibold">{{ t('common.customer') }} <i class="fa-solid fa-house float-right mt-1"></i></h3>
    <ul class="mt-5 flex flex-col space-y-5 w-full text-gray-600 dark:text-gray-200">
        <li class="px-2 py-1" :class="(componentInfo.position === 1) ? activeClass : ''"><router-link to='/customer/notice'>{{ t('common.notice') }}</router-link></li>
        <li class="px-2 py-1" :class="(componentInfo.position === 2) ? activeClass : ''"><router-link to='/customer/faq'>{{ t('common.faq') }}</router-link></li>
        <li class="px-2 py-1" :class="(componentInfo.position === 3) ? activeClass : ''"><router-link to='/request/form'>제작문의</router-link></li>
        <li class="px-2 py-1" :class="(componentInfo.position === 4) ? activeClass : ''"><router-link to="/customer/bug">{{ t('common.Bug') }}</router-link></li>
        <li class="px-2 py-1" :class="(componentInfo.position === 5) ? activeClass : ''"><router-link to='/customer/partner'>{{ t('common.Partnership') }}</router-link></li>
    </ul>
</div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useMemberStore } from '@/store';
import { AuthService } from '@/services';
import { BoardContent, BoardRepository,Member } from '@gn2studio/gn2.library.common';
import config from '@/config';
import { onMounted, ref } from 'vue';
import { MessageBox } from '@/utility';
import { useRouter } from 'vue-router';

interface menuParameter {
    class?:string|null,
    position:number
};

const router = useRouter();
const { t, locale } = useI18n();
const auth = new AuthService();
const memberStore = useMemberStore();
const property = defineProps<menuParameter>();
const activeClass = 'bg-slate-900 text-white rounded-md pl-4 pt-2 shadow-md';

var boardRep = new BoardRepository(config.apis.board, '', config.global.slug);
var componentInfo = ref({
    key:0,
    position:0,
    class:'',
    isLoggedIn:false,
    isLoaded:false,
    item:[] as BoardContent[],
    isPopup:false,
    accessToken:'',
    member:new Member(),
    data: {
        title : '',
        body : ''
    }
});

onMounted(async () => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.position = property.position;
    componentInfo.value.isLoaded = true;

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        boardRep = new BoardRepository(config.apis.board, token, config.global.slug);
        componentInfo.value.isLoggedIn = true;
    }
});

</script>